import React from 'react';
import Preload from '../img/preloader.gif';

const Preloader = () => {
	return (
		<div className="preloader">
			<img src={Preload} alt="Preloader" />
		</div>
	)
}

export default Preloader;
