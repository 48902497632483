import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoteTimerMobile from '../components/LoteTimerMobile';
import StarIcon from '@mui/icons-material/Star';
import Lottie from 'lottie-react';
import loader from '../img/loader.json';

const InvernadaMobile = (props) => {

	const [status, setStatus] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		newState: false,
		showStatus: [],
		buyType: 0,
		expanded: false
	});

	useEffect(() => {
		if(props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue <= 1) {
			setState((prev) => ({ ...prev, buyType: 1 }));
		}

		if(props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue >= 1) {
			setState((prev) => ({ ...prev, buyType: 0 }));
		}

		if(props.data.lote.buyoutPrice <= 0 && props.data.lote.minOfferValue >= 1) {
			setState((prev) => ({ ...prev, buyType: 2 }));
		}
	}, [])

	function updateStatus(status) {
		setStatus(status);
		setLoading(false);
	}

	return (
		<>
			{ !loading ?
				<div className="lote-wrapper">

					<LoteTimerMobile
						updateStatus={ updateStatus }
						images={ props.data.lote.images }
						videos={ props.data.lote.videos }
						name={ props.data.lote.name }
						end={ props.data.lote.end }
						loteType={ "invernada" }
						loteIdInvernada={ props.data.lote.id }
						start={ props.data.lote.start } />
					<hr className="hr-separate" />

					<div className="timer-lote-offer-wrapper">
						<div className="lote-offer-type mobileSpacing">
							<div className={ state.buyType === 1 ? "buy-type-block block-blue" : "buy-type-block block-green" }>
								{ state.buyType === 1 ? "Compra directa" : "Pre-oferta" }
							</div>
						</div>
					</div>

					{ props.data.lote.minOfferValue > 1 && (
						<div className="lote-last-offer-wrapper">
							<p>ÚLTIMA OFERTA</p>
							<p>{ props.data.offer.toLocaleString('es-AR')} Gs.</p>
						</div>
					)}

					<hr className="hr-separate" />
					<div className="lote-properties">
						<div className="property-flex">
							<p>Establecimiento: </p>
							<p>{ props.data.lote.stablishment }</p>
						</div>

						<div className="property-flex">
							<p>Localidad: </p>
							<p>{ props.data.lote.location }</p>
						</div>

						{ props.data.isYourOffer ?
							<div className="lote-your-offer">
							<StarIcon />
								Tu Pre-oferta
							</div>
							:
							props.data.lote.minOfferValue > 1 && (
								<div className="property-block">
									<p className="green-text">{ props.data.userAlias ? `Ofertante: ${props.data.userAlias}` : "No hay ofertas" }</p>
								</div>
							)
						}
					</div>

					<hr className="hr-separate" />

					<div className="lotes-props-invernada">
						<div className="lote-props-mobile-wrapper">
							<div className="lote-prop-mobile-block">
								<div className="property-mobile-invernada-flex">
									<p>Categoría:</p>
									<p>{ props.data.lote.category }</p>
								</div>

								<div className="property-invernada-flex">
								<p>Raza:</p>
									<p>{ props.data.lote.race }</p>
								</div>

							</div>

							<div className="lote-prop-mobile-block">
								<div className="property-mobile-invernada-flex">
								<p>Pelaje:</p>
									<p>{ props.data.lote.fur }</p>
								</div>
								<div className="property-mobile-invernada-flex">
									<p>Carimbo:</p>
									<p>{ props.data.lote.carimbo }</p>
								</div>
							</div>

							<div className="lote-prop-mobile-block">

								<div className="property-mobile-invernada-flex">
									<p>Cantidad:</p>
									<p>{ props.data.lote.quantity }</p>
								</div>

								<div className="property-mobile-invernada-flex">
									<p>Peso Promedio:</p>
									<p>{ props.data.lote.averageWeight }</p>
								</div>
							</div>

							<hr className="hr-separate" />
							<div className="lote-prop-mobile-obs-block">
								<div className="property-mobile-invernada-flex">
									<p>Observación Corta:</p>
									<p>{ props.data.lote.shortObs }</p>
								</div>
							</div>
						</div>
					</div>

					<div className="lote-action-buttons mobileSpacing">
						<Link to={ "/invernada/ofertar/" + props.data.lote.id }
							props={{ minOfferIncrement: props.minOffer }}
							className={ status ? "btn-lote btn-lote-offer" : "btn-lote btn-lote-disabled"}>
							{ (props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue <=1) && ("Comprar") }
							{ (props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue > 1) && ("Pre-ofertar") }
							{ (props.data.lote.buyoutPrice <= 0 && props.data.lote.minOfferValue >= 1) && ("Pre-ofertar") }
						</Link>

						{ !status && (
							<Link to={"/invernada/ofertar/"+ props.data.lote.id} className="btn-lote btn-lote-show-more">
								Ver información del lote
							</Link>
						)}
					</div>
				</div>
				:
				<div className="lottie-loading">
					<Lottie
						animationData={loader}
						loop={true}
						className="lottie-loading-anim"
					/>
				</div>
			}
		</>
	)
}

export default InvernadaMobile;

