import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TermsInvernada from './TermsInvernada';
import TermsReproductores from './TermsReproductores';
import TermsConsumo from './TermsConsumo';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: "DM Sans",
	},
	palette: {
		primary: {
			main: '#19513c'
		},
		secondary: {
			main: '#ff9900'
		},
	},
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box className="box-total-wrapper" sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Terms = () => {
	document.title = "Términos y condiciones - El Rodeo Consignataria";
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banners/terminos_condiciones.png') } />
			<div className="properties-container">

				<ThemeProvider theme={theme}>
				<Box sx={{ width: '100%' }}>

					<Box>
						<Tabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
							<Tab className="button-tab" label="INVERNADA" {...a11yProps(0)} />
							<Tab className="button-tab" label="REPRODUCTORES" {...a11yProps(1)} />
							<Tab className="button-tab" label="FERIAS DE CONSUMO" {...a11yProps(2)} />
						</Tabs>
					</Box>

					<TabPanel value={value} index={0}>
						<TermsInvernada />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<TermsReproductores />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<TermsConsumo />
					</TabPanel>
				</Box>
				</ThemeProvider>
			</div>
		</div>
	)
}

export default Terms;
