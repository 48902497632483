import React, {useState, useEffect} from 'react';

const VideosComponent = (props) => {
	const [videos, setVideos] = useState([]);

	if(!props.videos || props.videos == undefined){
		return null;
	}

    else {
        useEffect(() => {
            let videosArr = props.videos.split(',');
            let videosArrFiltered = [];
            let videoVariable = '';

            try{
                videosArrFiltered = videosArr.map(video =>{
                    if(video.includes('v=')){
                        videoVariable = video.split('v=')[1].split('&')[0];
                    }else{
                        videoVariable = video.split('/')[3];
                    }
                    return videoVariable;
                })
            }catch(error){
                console.log(error);
            }

            setVideos(videosArrFiltered);
            }, [props]);
        return(
            <div className="VideosMargin iframeVideoContainer">
                { videos && (
                    videos.map( (video, i) =>
                        <iframe
                            width="730"
                            height="548"
                            src= {
                                "https://www.youtube.com/embed/" + video
                            }
                            title=":El rodeo lote"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            key={i}>
                        </iframe>

                    )
                )}
            </div>
        )
    }
}

export default VideosComponent;
