import React from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
function FileList({ files }) {
	return (
		<>
			{ files && (
				<ul className="ul-file-list">
					{ files.map(file => (
						<li>
							{ file.uploadResult && (
								<a
									className="file-list-link"
									target="_blank"
									href={`${process.env.REACT_APP_URL_BASE}/Uploads/${file.uploadResult.storedFileName}`}>
									<SaveAltIcon />
									{ file.title }
								</a>
							)}
						</li>
					))}
				</ul>
			)}
		</>
	)
}

export default FileList;
