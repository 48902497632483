import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import axios from 'axios';
import FileList from '../components/FileList';
import Preloader from '../components/Preloader';

const HabilitarCarpeta = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;
		axios.get(process.env.REACT_APP_URL_API + configuration.GET_HABILITAR_CARPETA)
			.then((response) => {
				if (!ignore) {
					console.log(response.data.data);
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
		})
		return () => {
			ignore = true;
		}
	}, []);

	return (
		<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banners/habilitar_carpeta.png') } />
			<div className="properties-container">

				{ !isLoading ?
					<div>
						{ data ?
							data.carpeta && data.archivos ?
								<>
									<div className="folder-file-list-wrapper">
										<h3>{ data.carpeta.title }</h3>
										<p>{ data.carpeta.description }</p>
									</div>

									<h3 className="green-text content-minor-title">Lista de archivos de interés</h3>
									{ data.archivos.length > 0 ?
										<FileList files={ data.archivos } />
										:
										<p className="mt-1">No hay archivos disponibles para descargar.</p>
									}
								</>
								:
							null
							:
							<p>No hay archivos disponibles para descargar.</p>
						}
					</div>
					:
					<div className="lottie-loading">
						<Preloader />
					</div>
				}
			</div>
		</div>
	)
}

export default HabilitarCarpeta;
