import React from 'react';
import edit from '../img/edit_fill.png';
import {Link, NavLink, Navigate} from 'react-router-dom';
import configuration from '../config/configuration.json';


import Cookies from 'universal-cookie';
const cookies = new Cookies();

class UserProfile extends React.Component{
  state = {
    redirect: false,
    businessName: "",
    cellphone: "",
    department: "",
    direction: "",
    email: "",
    lastName: "",
    name: "",
    password: "",
    ruc: "",
    stablishment: "",
    stablishmentCode: "",
    stablishmentDepartment: "",
    stablishmentDirection: "",
    stablishmentTelephone: "",
    status: "",
    telephone: ""
  }
  fetchData = async () =>{
    console.log("datas");



    try{
      const daw = await fetch(process.env.REACT_APP_URL_API + configuration.GET_USER_DATA,{
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "accept": "text/plain",
          'Authorization': 'Bearer ' + cookies.get('token')
        },
        cache: 'default',

      })
      .then((response)=> response.json())
      .then((data)=> {

        console.log(data.data.userData);
        this.setState({
          loading: true,
          businessName: data.data.userData.businessName,
          cellphone: data.data.userData.cellphone,
          department: data.data.userData.department,
          direction: data.data.userData.direction,
          email: data.data.userData.email,
          lastName: data.data.userData.lastName,
          name: data.data.userData.name,
          password: data.data.userData.password,
          ruc: data.data.userData.ruc,
          stablishment: data.data.userData.stablishment,
          stablishmentCode: data.data.userData.stablishmentCode,
          stablishmentDepartment: data.data.userData.stablishmentDepartment,
          stablishmentDirection: data.data.userData.stablishmentDirection,
          stablishmentTelephone: data.data.userData.stablishmentTelephone,
          status: data.data.userData.status,
          telephone: data.data.userData.telephone,
          whatsappNotification: data.data.userData.whatsappNotification,
          emailNotification: data.data.userData.emailNotification,

        },()=>{
          this.setState({loading: true})
        })
      })
    }
    catch(error){
      console.log(error);
    }

  }
  wpChange=(e)=>{
    this.setState({whatsappNotification: e.target.checked},()=>{console.log(this.state.whatsappNotification)})
  }
  onSubmit = async () =>{
    console.log(this.state);
    var snackbarMsj = document.getElementById('snackbar_msj');
    var snackbarBox = document.getElementById('snackbar_box');

    try{
      const daw = await fetch(process.env.REACT_APP_URL_API + configuration.EDIT_USER_DATA,{
        method: 'PUT',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "accept": "text/plain",
          'Authorization': 'Bearer ' + cookies.get('token')
        },
        body: JSON.stringify({
          businessName: this.state.businessName,
          cellphone: this.state.cellphone,
          department: this.state.department,
          direction: this.state.direction,
          email: this.state.email,
          lastName: this.state.lastName,
          name: this.state.name,
          password: this.state.password,
          ruc: this.state.ruc,
          stablishment: this.state.stablishment,
          stablishmentCode: this.state.stablishmentCode,
          stablishmentDepartment: this.state.stablishmentDepartment,
          stablishmentDirection: this.state.stablishmentDirection,
          stablishmentTelephone: this.state.stablishmentTelephone,
          telephone: this.state.telephone,
          whatsappNotification: this.state.whatsappNotification,
          emailNotification: this.state.emailNotification,
          status: this.state.status,
        }),
        cache: 'default',
      })
      .then((response)=> response.json())
      .then((data)=> {
          console.log(data);
          if(data.status == "Ok"){
            cookies.set('user', this.state.name, { path: '/' });
            cookies.set('token', data.data.token, { path: '/' });
            cookies.set('mail', this.state.email, { path: '/' });
            cookies.set('lastName', this.state.lastName, { path: '/' });


            snackbarMsj.innerText = data.data.errorInfo;
            snackbarBox.classList.add("snackbar-hd");
            this.setState({redirect: true})

            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
            },3000)
            setTimeout(()=>{
              window.location.reload(false);
            },10)
          }else{
            snackbarMsj.innerText = data.data.errorInfo;
            snackbarBox.classList.add("snackbar-hd");
            snackbarBox.classList.add("snackbar-error");


            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
              snackbarBox.classList.remove("snackbar-error");
              //window.location.reload(false);

            },3000)
          }


      })
    }
    catch(error){
      console.log(error);
      snackbarMsj.innerText = "error";
      snackbarBox.classList.add("snackbar-hd");
      snackbarBox.classList.add("snackbar-error");


      setTimeout(()=>{
        snackbarBox.classList.remove("snackbar-hd");
        snackbarBox.classList.remove("snackbar-error");


      },3000)
    }
  }
  componentDidMount(){
    console.log(this.props.token);
    this.fetchData();
  }
  render(){

    return (
      <div>
        { this.state.redirect ? (<Navigate to="/profile"/>) : null }
        <div className="userProfile_container">
          <div className="userProfile_box">

            <div className="userProfile_box_header">
              <div className="userProfile_box_header_datos">
                <p className="userProfile_box_header_datos-conf">Configuracion</p>
                <p className="userProfile_box_header_datos-user">Usuario</p>
                <h2>{cookies.get('user')} {cookies.get('lastName')}</h2>
              </div>
              <div className="userProfile_box_header_btn">

              </div>
            </div>

            <div className="userProfile_box_contenido">

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Nombre *</p>
                <input
                  className={this.state.name == "" ? "userProfile_box_contenido_bloque_input userProfile_box_contenido_bloque_input-red " : "userProfile_box_contenido_bloque_input"}
                  value={this.state.name}
                  onChange={e => {this.setState({name: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label "> Apellido *</p>
                <input
                  className={this.state.lastName == "" ? "userProfile_box_contenido_bloque_input userProfile_box_contenido_bloque_input-red " : "userProfile_box_contenido_bloque_input"}
                  value={this.state.lastName}
                  onChange={e => {this.setState({lastName: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Razón Social</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.businessName}
                  onChange={e => {this.setState({businessName: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> RUC</p>
                <input
                  className={this.state.ruc == "" ? "userProfile_box_contenido_bloque_input userProfile_box_contenido_bloque_input-red " : "userProfile_box_contenido_bloque_input"}
                  value={this.state.ruc}
                  onChange={e => {this.setState({ruc: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Establecimiento</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.stablishment}
                  onChange={e => {this.setState({stablishment: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Dirección del Establecimiento</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.stablishmentDirection}
                  onChange={e => {this.setState({stablishmentDirection: e.target.value})}}
                  />

              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Teléfono del Establecimiento</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.stablishmentTelephone}
                  onChange={e => {this.setState({stablishmentTelephone: e.target.value})}}
                  />

              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Código del Establecimiento</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.stablishmentCode}
                  onChange={e => {this.setState({stablishmentCode: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Teléfono</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.telephone}
                  onChange={e => {this.setState({telephone: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Célular *</p>
                <input

                  className={this.state.cellphone == "" ? "userProfile_box_contenido_bloque_input userProfile_box_contenido_bloque_input-red " : "userProfile_box_contenido_bloque_input"}
                  value={this.state.cellphone}
                  onChange={e => {this.setState({cellphone: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Dirección</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.direction}
                  onChange={e => {this.setState({direction: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Departamento</p>
                <input
                  className="userProfile_box_contenido_bloque_input"
                  value={this.state.department}
                  onChange={e => {this.setState({department: e.target.value})}}
                  />
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Email *</p>
                <input
                  className={this.state.email == "" ? "userProfile_box_contenido_bloque_input userProfile_box_contenido_bloque_input-red " : "userProfile_box_contenido_bloque_input"}
                  value={this.state.email}
                  onChange={e => {this.setState({email: e.target.value})}}
                  />
              </div>



            </div>
            <hr/>
            <div className="cajasCheckbox">
              <p>Quiere recibir notificaciones?</p>
              <br/>

              <input
                className="checkbox"
                type="checkbox"
                id='Whatsapp'
                name='Whatsapp'
                autoComplete="on"
                checked={this.state.whatsappNotification}
                onChange={e => {this.setState({whatsappNotification: e.target.checked})}}
              /><label class="register_checkbox_label">Whatsapp</label><br/>


              <br/>

              <input
                className="checkbox"
                type="checkbox"
                id='Mail'
                name='Mail'
                autoComplete="on"
                checked={this.state.emailNotification}
                onChange={e => {this.setState({emailNotification: e.target.checked})}}
              /><label class="register_checkbox_label">Mail</label><br/>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>


            <div className="userProfile_botonera">
              <NavLink to="/profile">
                <div className='btn btn-long btn_ctaBorder'>Descartar</div>
              </NavLink>
              <div className='btn btn-long  btn_cta' onClick={this.onSubmit}>Guardar</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserProfile;
