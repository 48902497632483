import React from 'react';

const Faq = () => {
	return (
		<div className="general-container">
			<div className="properties-container">
				<h1 className="normal-top-title">PREGUNTAS FRECUENTES</h1>
				<p>En construcción...</p>
			</div>
		</div>
	)
}

export default Faq;
