import React from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookIcon,
	TwitterIcon,
	WhatsappIcon,
} from "react-share";

const ShareButtons = (props) => {
	return (
		<>
			<div className="share-buttons-wrapper">
				<FacebookShareButton
					url={ props.url }>
					<FacebookIcon size={40} round={true} />
				</FacebookShareButton>

				<TwitterShareButton
					title={ props.title }
					url={ props.url }>
					<TwitterIcon size={40} round={true} />
				</TwitterShareButton>

				<WhatsappShareButton
					title={ props.title }
					url={ props.url }>
					<WhatsappIcon size={40} round={true} />
				</WhatsappShareButton>
			</div>
		</>
	)
}

export default ShareButtons;
