import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import axios from 'axios';
import configuration from '../config/configuration.json';
import Preloader from '../components/Preloader';

const ConsumoLiquidacionesInformes = () => {
	const user = window.location.href.split('/')[5];
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_SELLER_DATES + "/" +user)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	function formatDate(date) {
		let newDate = new Date(date);
		let options = { day: "numeric", year: "numeric", month: "long" };
		return new Intl.DateTimeFormat("es-AR", options).format(newDate);
	}

	function getDay(date) {
		let newDate = new Date(date);
		let options = { weekday: "long" };
		return new Intl.DateTimeFormat("es-AR", options).format(newDate);
	}

	return (
		<div className="consumoContainer">
			<div className="consumoContent">
				<ConsumoTopTitle />
				<ConsumoTopButtons active={3} />

				{ !isLoading ?
					<>
						{ data && (
							<>
								<div className="consumoDataContent">
									<h2 className="mobileSpacing">INFORME DE SU VENTA</h2>
									<h3 className="noTextDecoration">NOMBRE: { data.sellerName }</h3>
								</div>

								{ data.dates && (
									<div className="consumoLiquidacionesInformesWrapper">
										{ data.dates.sort((a,b) => b.feriaDate > a.feriaDate ? 1 : -1).map((info, index) => (
											<NavLink key={index}
												to={`/consumo/liquidaciones/${user}/informes/${info.feriaId}/f/${info.feriaDate}`}
												className="consumoLiquidacionesInformesButton">
												<p>{ getDay(info.feriaDate).toUpperCase() }</p>
												<p>{ formatDate(info.feriaDate).toUpperCase() }</p>
											</NavLink>
										))}
									</div>
								)}
							</>
						)}
					</>
					:

					<div className="lottie-loading">
						<Preloader />
					</div>
				}
			</div>
		</div>
	)
}

export default ConsumoLiquidacionesInformes;
