import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function ReactLightBox(props) {
  const [toggler, setToggler] = useState(false);
  if(props.img != null){
    var imgArr = props.img.split(',');
  }else{
    var imgArr = ""
  }

  return (
    <div>
      <button onClick={() => setToggler(!toggler)}>
      </button>
      <FsLightbox
        toggler={props.toggle}
        sources={imgArr}

        types={
           [
             ...new Array(imgArr.length).fill('image')
           ]
         }
      />
    </div>
  );
}

export default ReactLightBox;
