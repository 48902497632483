import React from 'react';

function TermsInvernada() {
	return (
		<div className="body-text-wrapper">
			<div className="body-text-title-block">
				<img src={ require('../img/money.png') } />

				<h2 className="body-text-titles">
					Unidades de Venta
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>
					Los animales se comercializarán en guaraníes x kilogramo IVA (5%) incluido (G$ / Kg).
				</li>
				<li>
					Las vaquillas y vacas preñadas se comercializarán en guaraníes x animal IVA (5%) incluido (por punta).
				</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/hands.png') } />

				<h2 className="body-text-titles">
					Pagos
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>El pago es al CONTADO previo al retiro de los animales.</li>
				<li>El COMPRADOR deberá regularizar su compra al día siguiente, dejando un cheque como seña de trato por su
				respectiva compra y comisión resultante del peso de certificación.</li>

				<li>El comprador tendrá la OPCIÓN de acudir al embarque, pesar nuevamente los animales, oficiando este
				como el peso final para la liquidación.</li>
				<li>La OPCIÓN del comprador de acudir al embarque tiene un plazo de vencimiento de 24 horas posterior al
				cierre de la operación y tiene que ser comunicado por escrito a la consignataria.</li>
				<li>Si el COMPRADOR no toma la OPCIÓN de acudir al embarque, cualquier reclamo futuro no tendrá valor, ni
				será responsable la consignataria.</li>
				<li>El Rodeo tiene la exclusividad de la operación por un período de validez/formalización de 15 días, caso
				contrario cualquiera de las partes podrá desistir de la misma.</li>
				<li>Los pagos al vendedor se realizarán al día siguiente del embarque de los animales.</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/certificado.png') } />

				<h2 className="body-text-titles">
					Clasificación y Certificación
				</h2>
			</div>

			<p className="body-text-p-normal">
				El Rodeo S.A. es la responsable de la certificación de los animales, pudiendo elegir técnicos idóneos en la materia
				para llevar a cabo dicha labor.
			</p>

			<p className="body-text-p-title">
				Identificación:
			</p>

			<ul className="ul-list-text">
				<li>los animales serán identificados obligatoriamente a fuego o con ácido con carimbos de numeración del 1 al 0 de acuerdo a la conveniencia del momento.</li>
			</ul>

			<p className="body-text-p-title">
				Número de animales por lote:
			</p>

			<ul className="ul-list-text">
				<li>El número de animales a comercializarse será de a cargas completas, entendiéndose que no es la misma cantidad de animales en función de la categoría en cuestión.</li>
			</ul>

			<p className="body-text-p-title">
				Animales Preñados:
			</p>

			<ul className="ul-list-text">
				<li>Deberán ser acompañados con certificado de preñez emitido por un veterinario responsable con un plazo menor a 30 días de la feria.</li>
				<li>Para el comprador, se entiende que el lote puede tener una merma de 3% de preñez por el stress sufrido por el encierre, carga, transporte y trabajos de corral en general.</li>
				<li>El comprador podrá palpar nuevamente los animales con su veterinario/personal designado antes del
				embarque.</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/truck.png') } />

				<h2 className="body-text-titles">
					Entrega de lotes
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>EL RODEO S.A. estará a cargo de la entrega de los animales. La autorización de la misma se llevará a cabo
				una vez abonado y acreditado el dinero en la cuenta de la firma organizadora.</li>
				<li>TRANSPORTE: el transporte de los animales estará a cargo del comprador (salvo casos específicos donde sea
				coordinado entre las partes).</li>

				<li>El Peso:
					<ul className="ul-list-text">
						<li>En Condiciones normales de entrega, los kilogramos enunciados en el presente catálogo de venta y en la pantalla, que corresponden al momento de la certificación, no deberían tener una diferencia de
							hasta un 3% pasados los 10 días de llegada del lote y pesados por el comprador. El comprador deberá
							mantener a los animales en esos días con buenas condiciones de cuidado y alimentación.</li>

						<li>En Condiciones anormales de entrega (tiempos fuera de plazo, dificultades de traslado por caminos,
							épocas del año, etc.) se aceptará una tolerancia de hasta un 10% transcurridos los 10 días
							mencionados. El vendedor es responsable de mantener los animales en buenas condiciones de
							alimentación y cuidado luego de la certificación y hasta el momento del embarque. Los lotes pueden
							ser certificados nuevamente si la consignataria lo solicita.</li>
						<li>Los animales podrán ser retirados hasta 10 días posteriores a la feria, una vez transcurrido ese plazo
							sin justificación alguna, la responsabilidad será por cuenta del comprador y éste último deberá
							abonar gs 2.200 / animal / día en concepto de pastaje y gastos.</li>
						<li>El vendedor es responsable de de todos los requisitos legales y sanitarios para poder cumplir con la
							venta y entrega al comprador de la documentación correspondiente a la operación para el traslado
							de los animales al lugar de destino final que indique el comprador.</li>
					</ul>
				</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/chat.png') } />

				<h2 className="body-text-titles">
					Reclamos
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>Cualquier reclamo podrá realizarse dentro del plazo de 10 días a partir de la entrega del lote. El mismo deberá
					ser entregado por medio escrito en las oficinas de EL Rodeo S.A. informando los inconvenientes en cuestión. No
					se tomarán reclamos posteriores al vencimiento de dicho plazo.</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/incumplimiento.png') } />

				<h2 className="body-text-titles">
					Incumplimientos
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>En caso que el comprador desista de la operación perderá el monto abonado en concepto de seña. En caso
				que el vendedor desista de la operación deberá abonar al vendedor el monto de la seña más la misma suma
				en concepto de multa.</li>
				<li>El vendedor sólo podrá desistir de la operación sin costo previo al momento de la certificación. Posterior a la
				misma, en caso de retirar el lote deberá abonar Gs. 500.000 por lote inscripto más Gs. 10.000 por cada
				cabeza certificada. Una vez realizada la venta en caso de entregar menos animales que los vendidos sin
				causa alguna, el vendedor deberá abonar al comprador Gs. 150.000 por animal y Gs. 40.000 por animal a la
				firma consignataria en concepto de penalidad dentro de los 3 días corridos posteriores a la fecha de
				entrega.</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/arbitraje.png') } />

				<h2 className="body-text-titles">
					Arbitraje
				</h2>
			</div>

			<p className="body-text-p-normal">
				Se establecerá una comisión de arbitraje para la interpretación de las clausulas establecidas en el presente y
				resolución de conflictos que surgieran entre las partes en relación con las operaciones de compra-venta de
				ganado. La misma estará conformada por un representante de El Rodeo S.A., un representante de la Asoc. de
				Criadores de Brahman del Paraguay y un representante de la Asociación Rural del Paraguay.
			</p>
		</div>
	)
}

export default TermsInvernada;
