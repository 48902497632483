import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import configuration from '../config/configuration.json';
import Lottie from 'lottie-react';
import loader from '../img/loader.json';
import axios from 'axios';
import cookie from 'js-cookie';
import Preloader from '../components/Preloader';

const OfferHistory = () => {
	if(!cookie.get('token')) {
		window.location.href = '/login';
	}

	console.log(window.location.href.split('/'));

	const remId = window.location.href.split('/')[4];
	const lName = window.location.href.split('/')[5];

	const [isLoading, setIsLoading] = useState(false);
	const [remateInfo, setRemateInfo] = useState([]);
	const [data, setData] = useState([]);

	function formatLocaleDate(date) {
		let newDate = new Date(date);
		return newDate.toLocaleDateString();
	}

	function formatLocaleTime(date) {
		let newDate = new Date(date);
		return newDate.toLocaleTimeString();
	}

	useEffect(() => {

		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.post(process.env.REACT_APP_URL_API + configuration.POST_USER_OFFERS, {
			},
				{
					headers: {
						"Authorization": `Bearer ${cookie.get('token')}`
					}
			})
			.then((response) => {
				if (!ignore) {

					console.log(response.data.data);
					setRemateInfo(response.data.data.rematesOffers);

					if(response.data.data.rematesOffers) {
						response.data.data.rematesOffers.filter(r => {
							if(r.remateId === parseInt(remId)) {
								//let remates = r.loteOffers.filter(lote => {lote.loteName === lName)
								console.log(r.loteOffers);
								r.loteOffers.map(lote => {
								 if(lote.loteName === lName) {
										setData(lote);
									}
								})

								//r.loteOffers.filter(lote => {
								//	if(lote.loteName === lName) {
								//		return lote
								//	}
								//})
								//let remates = r.loteOffers.some(({loteName}) => loteName.loteName === lName)
								//console.log(remates);
								//return remates
							}
							//return loteOferta;
						});
					}

					//setData(filteredData);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
			{ !isLoading ?
				<>
					<div className="breadcrum">
						<u><Link to="/">Home</Link></u> {" > "}
						<Link to="/historial-ofertas">Historial de Ofertas</Link> {" > "}
						{ data && remateInfo && remateInfo[0] ? `${remateInfo[0].remateName} - ${data.loteName}` : '' }
					</div>

					<div className="offer-history-top-container">
						<div className="offer-history-global-container">
							<div className="offer-history-container">
								<h1>HISTORIAL DE OFERTAS REPRODUCTORES</h1>
								<>
									{ data && (
										<>
											{ remateInfo && (
												remateInfo[0] && (
													<h2>{ `${remateInfo[0].remateName} - ${data.loteName}` }</h2>
												)
											)}

											<div className="list-container">
												<table className="customers offer-history-table">
													<tr className="backgroundTransparent">
													<th>Ofertas</th>
													<th>Hora</th>
														<th style={{ textAlign: 'center' }}>Fecha</th>
													</tr>
													{ data.ofertas && (

														data.ofertas.map((loteOffer, index) => (
														<tr key={index}>
																<td>{ loteOffer.offer.toLocaleString() } Gs.</td>
																<td className="color-orange">{ formatLocaleTime(loteOffer.createdAt) } Hs</td>
																<td style={{ textAlign: 'center' }}>{ formatLocaleDate(loteOffer.createdAt) }</td>
															</tr>
														))
													)}
												</table>
											</div>
										</>
									)}
								</>

							</div>
						</div>
					</div>
				</>
				:
				<div className="lottie-loading">
					<Preloader />
				</div>
			}
		</>
	)
}

export default OfferHistory;

