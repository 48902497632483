import React from "react";


class Proximamente extends React.Component{
  constructor(props){
    super(props);

  }
  render(){

    return(
      <div>
        <h1>Proximamente</h1>
      </div>
    );
  }
}

export default Proximamente;
