import React from 'react';
import LoteFicha from '../components/loteFicha';



class FichaPage extends React.Component{
  render(){
    return (
      <LoteFicha

      />
    )
  }
}

export default FichaPage;
