import React from 'react';

class Snackbar extends React.Component{
  render(){
    return (
      <div className="snackbar">
        <div className='snackbar_message ' id="snackbar_box">
          <p id="snackbar_msj"></p>
        </div>
      </div>
    )
  }
}

export default Snackbar;


//snackbar-hd snackbar-error
