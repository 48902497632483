import React, { useState, useEffect } from "react";
import {useParams, useNavigate, useLocation, redirect, Navigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import configuration from '../config/configuration.json'
import Lottie from 'lottie-react';
import form from '../json/form.json';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Login extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      recomplete: false,
      logged: false,
      completed: false,
      userName: "",
      lastName: "",
      userMail: "",
      password: "",
      token: "",
      mailCheck: false,
      passCheck: false,
			currentUrl: window.location.href.split("=")[1],
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.canello = this.props;
  }

  async onSubmit(e){

    var snackbarMsj = document.getElementById('snackbar_msj');
    var snackbarBox = document.getElementById('snackbar_box');
    var cajaTextoBtn = document.getElementById('mensajeBtn');

    e.preventDefault();

    if(this.state.mailCheck && this.state.passCheck){

      try {
        let res = await fetch(process.env.REACT_APP_URL_API + configuration.LOGIN, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "text/plain"
          },
          body: JSON.stringify({
            userMail: this.state.userMail,
            password: this.state.password
          }),
        })
        .then((response)=> response.json())
        .then((data)=> {

            if(data.status == 'Error'){
              mensajeInfo(data.data.errorInfo, true);
            }
            let camposCorrectos = true;
            if(data.status === 'Ok'){
              this.setState(this.state={
                ...this.state,
                token: data.data.token,
                userName: data.data.userData.name,
                userMail: data.data.userData.email,
                lastName: data.data.userData.lastName,
                logged: true
              });
              if(data.data.userData.name == "x"){
                this.setState({recomplete: true})
                mensajeInfo("Complete su informacion para continuar", true);
                camposCorrectos = false;

              }
              if(data.data.userData.lastName == "x"){
                this.setState({recomplete: true})
                mensajeInfo("Complete su informacion para continuar", true);
                camposCorrectos = false;

              }
              if(data.data.userData.cellphone == "x"){
                this.setState({recomplete: true})
                mensajeInfo("Complete su informacion para continuar", true);
                camposCorrectos = false;

              }
              if(data.data.userData.ruc == "x"){
                this.setState({recomplete: true})
                mensajeInfo("Complete su informacion para continuar", true);
                camposCorrectos = false;

              }

              if(camposCorrectos){
                mensajeInfo("Se ha logeado con exito", false);
                this.setState({completed: true});
                setTimeout(()=>{
                  window.location.reload(false);
                },1000)
              }


							if(this.state.currentUrl !== undefined) {
								window.location.replace(this.state.currentUrl);
							}
							else {
								window.location.replace('/');
							}
            }

            function mensajeInfo(msj, error){
              snackbarMsj.innerText =  msj;
              snackbarBox.classList.add("snackbar-hd");
              if(error){
                snackbarBox.classList.add("snackbar-error");
              }
              setTimeout(()=>{
                snackbarBox.classList.remove("snackbar-hd");
                snackbarBox.classList.remove("snackbar-error");
              },3000)
            }

            this.canello.handleUser(this.state.userName, this.state.logged, this.state.token, this.state.userMail, this.state.lastName) ;

        })
      } catch (err) {
        console.log(err);
      }
    }else{
      console.log("no se puede logear");
      cajaTextoBtn.innerText = "Complete correctamente los campos de arriba"
    }


  }
  handlerMail = (e) =>{
    var cajaTextoBtn = document.getElementById('mensajeBtn');
    cajaTextoBtn.innerText = " ";
    this.setState( {userMail: e.target.value} );
    var regEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(e.target.value);


    var cajaTextoMail = document.getElementById('mensajeMail')
    if(true){
      cajaTextoMail.innerText = " ";
      this.setState({mailCheck: true});
    }else{
      cajaTextoMail.innerText = "Ingrese un mail válido";
      this.setState({mailCheck: false});
    }
  }
  handlerPass = (e) =>{
    var cajaTextoBtn = document.getElementById('mensajeBtn');
    cajaTextoBtn.innerText = " ";
    this.setState( {password: e.target.value} );
    var regEx = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(e.target.value);

    var cajaTextoPass = document.getElementById('mensajePassword')
    if(true){
      cajaTextoPass.innerText = " ";
      this.setState({passCheck: true});
    }else{
      cajaTextoPass.innerText = "más de 8 letras, más de 1 dígito, más de 1 mayúscula.";
      this.setState({passCheck: false});
    }
  }
  componentDidMount(){
    var token = cookies.get('token');

		console.log(this.props);
		console.log(this.state.currentUrl);
    if(token){
      this.setState({logged: true})
    }

    var iniciar = document.getElementById('iniciar');
    window.addEventListener('keypress', function(e){


      if(e.key === "Enter"){
        e.preventDefault();
        iniciar.click();
      }

    })

  }
  render(){

    return(
      <div className="form-m">
      { this.state.recomplete ? null : null }
      <div className="form">
          <Lottie
            animationData={form}
            loop={false}
            className="form_img"
          />

        <form onSubmit={this.onSubmit}>
          <h3 className="text-center">Iniciar sesión</h3>
          <div className="form_box">

            <div className="form_box_item" >
              <label>Correo electrónico</label><br/>
              <input
                type="text"
                id='mail'
                name='mail'
                onChange={this.handlerMail}
                className="active"
              /><br/>
              <small id="mensajeMail"></small>
            </div>

            <div className="form_box_item">
              <label>Contraseña</label><br/>
              <input
                type="password"
                id='pass'
                name='pass'
                autoComplete="on"
                onChange={this.handlerPass}
              /><br/>
              <small id="mensajePassword"></small>
            </div>

          <button  type="submit" onClick={this.onSubmit} className="btn btn_cta" id="iniciar">Iniciar</button><br/>
          <small id="mensajeBtn"></small>

          </div>
        </form>
      </div>
      <p className="form_passwordReset">¿Olvidaste tu contraseña? Haga <Link to="/recuperacion-contrasena"><span>click aqui</span></Link></p>
      </div>
    );
  }
}

export default Login;
