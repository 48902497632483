import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import { NavLink } from 'react-router-dom';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import axios from 'axios';
import Preloader from '../components/Preloader';

const ConsumoPreciosAnteriores = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_YEARS_MONTHS_FERIA)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
			<div className="consumoContainer">
				<div className="consumoContent">
					<ConsumoTopTitle />
					<ConsumoTopButtons active={1} />

					<div className="consumoDataContent">
						<h2 className="mobileSpacing">ESTADÍSTICAS DE AÑOS ANTERIORES</h2>
					</div>

					{ !isLoading ?
						data && (
							<div className="consumoPreciosYears mobileSpacing">
								{ Object.entries(data)
								.sort(([key1, key2]) => { return parseInt(key2) - parseInt(key1) })
								.map(([key], index) => (
									<NavLink key={ index } to={`/consumo/precios-anteriores/${key}`} className="buttonYear">
										{ key }
									</NavLink>
								))}
							</div>
						)
						:
						<div className="lottie-loading">
							<Preloader />
					</div>
					}
				</div>
			</div>
		</>
	)
}

export default ConsumoPreciosAnteriores;
