import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import FotosGaleria from './fotosGaleria.js';
import configuration from '../config/configuration.json'
import LoteTimer from './LoteTimer';
import VideosComponent from '../components/videos-component';
import ReactLightBox from '../components/reactLightbox';
import { HashLink } from 'react-router-hash-link';
import Cookies from 'universal-cookie';
import VideoLote from './VideoLote.js';
import Lottie from 'lottie-react';
import loader from '../img/loader.json';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ShareButtons from './ShareButtons';
import StarIcon from '@mui/icons-material/Star';

const cookies = new Cookies();

const LoteFicha = () => {
	console.log(window.location.href.split('/'));

	let remateId = window.location.href.split('/')[4];
	let loteId = window.location.href.split('/')[6];

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [status, setStatus] = useState(false);
	const [toggle, setToggle] = useState(false);

	const [state, setState] = useState({
		proximamente: false,
		error404: false,
		img: "https://creadores.net/elrodeo/rem-1.jpeg,https://creadores.net/elrodeo/rem-1.jpeg,https://creadores.net/elrodeo/rem-1.jpeg,https://creadores.net/elrodeo/rem-1.jpeg",
		vid: null,
		oferta: 123,
		isYourOffer: false,
		offer: null,
		alias: null,
		userMail: cookies.get('mail'),
		statusBar: null
	});

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios.post(process.env.REACT_APP_URL_API + configuration.GET_LOTE_FROM_REMATES, {
				remateId: remateId,
				userMail: state.userMail
			}).then((response) => {

				if (!ignore) {
					var a = response.data.data.loteList;
					var b = a.filter(lote => {
						return lote.lote.id == loteId;
					});

					if (b.length == 0) {
						setState({ error404: true });
					}

					console.log(b);
					setData(b[0]);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			});

		}

		fetchData();

		return () => {
			ignore = true;
		}
		}, []);

	useEffect(() => {
		console.log(state.toggle);
		let statusInterval = setInterval(getStatus, 1000);
		return () => {
			clearInterval(statusInterval);
		}
	}, [data]);

	const getStatus = () => {
		if(data) {
			var dateStart = new Date(data.lote.remateStartDate);
			var dateEnd = new Date(data.lote.end);
			var dateNow = new Date();

			if (Math.floor(dateEnd / 1000) - Math.floor(dateNow / 1000) > 0) {
				if (Math.floor(dateStart / 1000) - Math.floor(dateNow / 1000) > 0) {
					setStatus(false);
					setState({
						statusBar:
						<div className="ficha_info ficha_info-proximamente">
							Lote {data.lote.name} - Remate { remateId }
						</div>
					});

				} else {
					setStatus(true);
					setState({
						statusBar:
						<div className="ficha_info ficha_info-open">
							Lote {data.lote.name} - Remate { remateId }
						</div>
					});
				}
			} else {
				setStatus(false);
				setState({
					statusBar:
					<div className="ficha_info ficha_info-close">
						Lote {data.lote.name} - Remate { remateId }
					</div>
				});
			}
		}

	}

	if (state.error404) {
		return (<Navigate to="/error" />)
	}

	useEffect(() => {
		console.log(data);
	}, [data]);

	return (
		<>
			{ !loading ?
				data && (
					<div>
						<Helmet>
							<meta charSet="utf-8" />
							<title>Ficha técnica - Lote { data.lote.name } Remate { remateId } - El Rodeo Consignataria</title>
							<meta
								property="og:title"
								content={ `Ficha técnica - Lote ${data.lote.name} Remate ${remateId}` } />
							<link rel="canonical" href={ window.location.href } />
							<meta
								property="og:url"
								content={ window.location.href } />
						</Helmet>

						<div className="breadcrum">
							<u>Home</u> > <Link to="/">Remates Reproductores</Link> {" > "}
							<HashLink to={"/lote/" + remateId + '#' + loteId }>Lotes</HashLink> {" > "} Ficha técnica
						</div>

						<div className="ficha_wrapp">
							<div className="ficha">
								{ state.statusBar }

								<div className="content-spacing">
									<div className="ficha_data">
										<div className="ficha_data_img">
											<VideoLote videos={ data.lote.videos } />

											<ShareButtons
												url={ window.location.href }
												title={ `Ficha técnica - Lote ${data.lote.name} Remate ${remateId}` } />
										</div>

										<div className="ficha_data_text">
											<div className="ficha_data_text_nombre">
												<h5>{data.lote.owner}</h5>
											</div>

											{ data.isYourOffer }
											<div className="ficha_data_text_puja">
												{
												data.isYourOffer
													?
													<div className="lote-your-offer">
													<StarIcon />
														TU OFERTA
													</div>
													:
													<div className="pujaForm_data_anotherOffer">
														<p>
															<span style={{ fontWeight: 700, marginRight: '3px' }}>{data.userAlias ? "Ofertante:" : "no hay ofertante"}</span>
															{data.userAlias ? data.userAlias : ""}
														</p>
												</div>
												}
											</div>

											<LoteTimer
												showMore={true}
												end={data.lote.end}
												start={data.lote.remateStartDate} />

											{data.offer > 1 && (
												<div className="no-border lote-last-offer-wrapper">
													<p className="no-padding">ÚLTIMA OFERTA</p>
													<p className="no-padding">{data.offer.toLocaleString('es-AR')} Gs.</p>
												</div>
											)}
										</div>
									</div>

									<div className="ficha_botonera">
										<div className="navbar_botonera">

											<Link to={`/lote/${ remateId }#${ loteId }`}>
												<div className="btn btn-ppl">
													VOLVER
												</div>
											</Link>


											{!status
												?
												<div className="btn btn-disabled">
													OFERTAR
												</div>
												:
												<Link
													className="btn btn_cta text-decoration-none"
													to={`/puja/${ data.lote.id }/${ data.lote.remateId }`}>
													OFERTAR
												</Link>
											}

										</div>
									</div>

									<div className="ficha_datosPrincipales">
										{data.lote.animales.map((animal, i) => (
											<div className="ficha_datosSecundarios" key={i}>
												{animal.propiedades.map((lote, i) => (

													<div className="ficha_datosSecundarios_cajas" key={i}>
														<p className="ficha_datosSecundarios_cajas_titulo">{lote.name}</p>
														<p className="ficha_datosSecundarios_cajas_dato">{lote.value}</p>
													</div>

												))}
											</div>
										))}
									</div>

									<div className="ficha_stats">

										{data.lote.description &&
											<div className="ficha_stats_caja">
												<div className="ficha_stats_caja_img">
													<img src="./img/observaciones-icon.svg" alt="" />
												</div>
												<div className="ficha_stats_caja_titulo">
													Observaciones
												</div>
												<div className="ficha_stats_caja_texto">
													{data.lote.description}
												</div>
										</div>
										}

									</div>
									<div id="video">
										{data.lote.videos !== null &&
										<>
												<div className="ficha_stats_caja_titulo">
													Videos
												</div>
												<VideosComponent videos={data.lote.videos} />
												<br />
										</>
										}
									</div>

									<VideosComponent videos={data.lote.videos} />
									<FotosGaleria img={ data.lote.imageUrl } toggle={ setToggle } />
									<ReactLightBox toggle={ toggle } img={ data.lote.imageUrl } />
								</div>

							</div>
						</div>
					</div>
				)
				:
				<div className="lottie-loading">
					<Lottie
						animationData={loader}
						loop={true}
						className="lottie-loading-anim"
					/>
				</div>
			}

		</>
	)
}

export default LoteFicha;
