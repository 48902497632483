import React from 'react';
import Masonry from 'react-masonry-css';
import Lightbox from '../components/lightbox';

class FotosGaleria extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      lbimg: 'as',
      sim: '"./../img/portada.jpeg","./../img/copia.jpg"',
      arr: [],
      imageLoading: true,
      breakpointColumnsObj:{
          default: 3,
          1100: 2,
          700: 1,
          500: 1
        }
    };
  }

  componentDidMount() {
    if(this.props.img) {
      this.setState({arr: this.props.img.split(",")}, function(){
        this.setState({imageLoading: false});
      })
    }
  }

  open = (evt) => {
    var as = document.getElementById('lightbox_img');
    as.classList.add("displayBlock")
    this.setState({lbimg: evt.target.alt })
  }

  render() {
    if(this.state.imageLoading === true){
      return null
    }

    return (
      <div className="ficha_galeria">
        <Lightbox img={this.state.lbimg}/>
        <p className="ficha_galeria_titulo">Imágenes</p>

        <div className="ficha_galeria_caja">
        <Masonry
            breakpointCols={this.state.breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            >

            {this.state.arr.map((img, i) => (
              <div key={i}>
                <button onClick={this.props.toggle}>
                  <img onError="this.style.display='none'" src={img} alt="" className="mass" />
                </button>
              </div>
            ))}
        </Masonry>
        </div>
      </div>
    )
  }
}

export default FotosGaleria;
