import React from 'react'

class VideoLote extends React.Component{
  render(){

    if(!this.props.videos){
      return null
    }
    if(this.props.videos == ""){
      return null
    }
    if(this.props.videos.includes('v=')){
      var link = "https://www.youtube.com/embed/" + this.props.videos.split('v=')[1].split('&')[0];
    }

    if(this.props.videos.includes('youtu.be')){
      var link = "https://www.youtube.com/embed/" + this.props.videos.split('/')[3];
    }

    var videosArr = this.props.videos.split(',');
    try{
      var videosArrFiltered = videosArr.map(video =>{
        var a
        if(video.includes('v=')){
          a = video.split('v=')[1].split('&')[0]
        }else{
          a = video.split('/')[3]
        }
        return a
      })
    }catch(error){
      console.log(error);
    }

    return(
      <div className="VideosMargin iframeVideoContainer">
        {videosArrFiltered.map((video, i) =>
					i == 0 ?
						<iframe
							width="730"
							height="548"
							src= {
								"https://www.youtube.com/embed/" + video + "?autoplay=1"
							}
							title=":El rodeo lote"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							key={i}>
						</iframe>
					: null

        )}
      </div>
    )
  }
}

export default VideoLote;

