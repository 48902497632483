import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import ConsumoTable from '../components/ConsumoTable';
import ConsumoMobile from '../components/ConsumoMobile';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import Preloader from '../components/Preloader';

const ConsumoPrecioYear = () => {
	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '68.75em' });
	const year = window.location.href.split('/')[5];

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	const months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO",
		"SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_YEARLY_RESUME + "/" + year)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
				<div className="consumoContainer">
					<div className="consumoContent">
						<ConsumoTopTitle />
						<ConsumoTopButtons active={1} />

					{ !isLoading ?
						data && (
							<>
								{ data.map((info, i) => (
									Object.entries(info).map(([key, value]) => (
										<div key={ key }>
											<div className="consumoDataContent">
												<h2 className="mobileSpacing">{ key == "mes" ? `${months[value - 1]} DE ${year}` : "" }</h2>
											</div>
											{ key == "ventas" ?
												<div className="contentDataTableContainer">
													<>
														{ isMobileToTablet && ( <ConsumoMobile
															calculationType={ "cant" }
															dataTable={ value } /> ) }
														{ isFromTablet && ( <ConsumoTable
															calculationType={ "cant" }
															dataTable={ value } /> ) }
													</>
												</div>
											: null
											}
										</div>
									))
								))}
							</>
						)
						:
						<div className="lottie-loading">
							<Preloader />
						</div>
					}
					</div>
				</div>
		</>
	)
}

export default ConsumoPrecioYear;
