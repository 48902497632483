import React from 'react';
import RematesList from '../components/rematesList';

const RematesPage = () => {
	return (
		<div>
			<RematesList />
		</div>
	)
}

export default RematesPage;
