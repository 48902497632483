import React, { useState, useEffect } from 'react';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import ConsumoTable from '../components/ConsumoTable';
import ConsumoMobile from '../components/ConsumoMobile';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import configuration from '../config/configuration.json';
import Preloader from '../components/Preloader';

const ConsumoEstadisticasMonths = () => {
	console.log(window.location.href.split('/'));
	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '46.25em' });

	const year = window.location.href.split('/')[5];
	const month = window.location.href.split('/')[7];

	const [expanded, setExpanded] = React.useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingCollapse, setIsLoadingCollapse] = useState(false);
	const [data, setData] = useState([]);
	const [information, setInformation] = useState(null);
	const [feria, setFeria] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_FERIAS_DATE_MONTHS+"/"+year+"/"+month)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, [year, month]);

	useEffect(() => {
		setIsLoadingCollapse(true);
		let ignore = false;
		axios.get(process.env.REACT_APP_URL_API + configuration.GET_FERIA_RESUME+"/"+feria)
			.then((response) => {
				if (!ignore) {
					setInformation(response.data.data);
					setIsLoadingCollapse(false);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				ignore = true;
			})
		}, [feria]);

	const handleChange = (panel, feriaId) => (event, isExpanded) => {
		if(isExpanded) {
				setFeria(feriaId);
				setExpanded(panel);
		} else {
			setExpanded(false);
			setInformation([]);
			setFeria(0);
		}
	};

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
			/>
	))(({ theme }) => ({
		color: '#19513C',
		backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(255, 255, 255, 1)',
		flexDirection: 'row',
		'&:before': {
			height: 0
		},
		'&.Mui-expanded': {
			minHeight: '48px'
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: 0
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			marginLeft: '8px'
		},
		'& .MuiAccordionSummary-content': {
			marginRight: theme.spacing(1),
			flexGrow: 'inherit',
		},
		'& .MuiAccordionSummary-content > p': {
			fontSize: '20px',
			fontWeight: 500
		}
	}));

	function formatDate(date) {
		let newDate = new Date(date);
		return newDate.toLocaleDateString('es-AR', { day: "2-digit", year: "numeric", month: "2-digit"});
	}

	function getDay(date) {
		let newDate = new Date(date);
		let options = { weekday: "long" };
		return new Intl.DateTimeFormat("es-AR", options).format(newDate);
	}

	return (
		<>
				<div className="consumoContainer">
					<div className="consumoContent">
						<ConsumoTopTitle />
						<ConsumoTopButtons active={2} />

						<div className="consumoDataContent">
							<h2 className="mobileSpacing">ESTADÍSTICAS SEMANALES Y MENSUALES</h2>
						</div>

					{ !isLoading ?
						<div className="consumoEstadisticasDatesWrapper">
							{ data && (
								data.map((feria,index) => (
									<Accordion
										disableGutters
										elevation={0}
										key={`AccKey${index}${feria.feriaId}`}
										id={`Accord${feria.feriaId}`}
										expanded={expanded === `panel${feria.feriaId}`}
										onChange={handleChange(`panel${feria.feriaId}`, feria.feriaId)}>

										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={`panel${feria.feriaId}-content`}
											id={`panel${feria.feriaId}-header`}
											>
											<Typography sx={{ width: '33%', flexShrink: 0 }} data-feria={feria.feriaId}>
												{ formatDate(feria.feriaDate) }
											</Typography>
										</AccordionSummary>

										<AccordionDetails style={{ padding: "2rem 0 4rem" }} id={ `feriaDetail${feria.feriaId}` }>
											{ !isLoadingCollapse ?
												information && (
													<>
														<Typography>
															{ Object.values(information)[0] && (
																<>
																	<div className="consumoDataContent">
																		<h2 className="mobileSpacing">INFORME DE FERIA</h2>
																		<h3>{ getDay(feria.feriaDate) } { formatDate(feria.feriaDate) }</h3>
																	</div>

																	{ isMobileToTablet && ( <ConsumoMobile
																		calculationType={ "cant" }
																		dataTable={ Object.values(information)[0] } /> ) }

																	{ isFromTablet && ( <ConsumoTable
																		calculationType={ "cant" }
																		dataTable={ Object.values(information)[0] } /> ) }
																</>
															)}
														</Typography>

														<Typography>
															{ Object.values(information)[1] && (
																<>
																	<div className="consumoDataContent">
																		<h2 className="mobileSpacing">Resumen General EL RODEO S.A.</h2>
																		<h3>Cuadro de Precios</h3>
																	</div>
																	{ isMobileToTablet && ( <ConsumoMobile
																		calculationType={ "cant" }
																		dataTable={ Object.values(information)[1] } /> )}

																	{ isFromTablet && ( <ConsumoTable
																		calculationType={ "cant" }
																		dataTable={ Object.values(information)[1] } /> )}
																</>
															)}
														</Typography>
													</>
												)
												:
												<p style={{ textAlign: "center", padding: "0", margin: 0, fontWeight: 800, fontSize: "1.2rem" }}>Cargando...</p>
											}
										</AccordionDetails>
									</Accordion>
								))
							)}
						</div>
						:
						<div className="lottie-loading">
							<Preloader />
						</div>
					}
					</div>
				</div>
		</>
	)
}

export default ConsumoEstadisticasMonths;
