import React from 'react';
import edit from '../img/edit_fill.png';
import configuration from '../config/configuration.json';
import {Link, NavLink} from 'react-router-dom';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class UserProfile extends React.Component{
  state = {
    businessName: "",
    cellphone: "",
    department: "",
    direction: "",
    email: "",
    lastName: "",
    name: "",
    password: "",
    ruc: "",
    stablishment: "",
    stablishmentCode: "",
    stablishmentDepartment: "",
    stablishmentDirection: "",
    stablishmentTelephone: "",
    status: "",
    telephone: ""
  }
  fetchData = async () =>{
    console.log("datas");

    try{
      const daw = await fetch(process.env.REACT_APP_URL_API + configuration.GET_USER_DATA,{
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "accept": "text/plain",
          'Authorization': 'Bearer ' + cookies.get('token')
        },
        cache: 'default',
      })
      .then((response)=> response.json())
      .then((data)=> {

        console.log(data.data.userData);
        this.setState({
          loading: true,
          businessName: data.data.userData.businessName,
          cellphone: data.data.userData.cellphone,
          department: data.data.userData.department,
          direction: data.data.userData.direction,
          email: data.data.userData.email,
          lastName: data.data.userData.lastName,
          name: data.data.userData.name,
          password: data.data.userData.password,
          ruc: data.data.userData.ruc,
          stablishment: data.data.userData.stablishment,
          stablishmentCode: data.data.userData.stablishmentCode,
          stablishmentDepartment: data.data.userData.stablishmentDepartment,
          stablishmentDirection: data.data.userData.stablishmentDirection,
          stablishmentTelephone: data.data.userData.stablishmentTelephone,
          status: data.data.userData.status,
          telephone: data.data.userData.telephone,
          whatsappNotification: data.data.userData.whatsappNotification,
          emailNotification: data.data.userData.emailNotification,
        },()=>{
          this.setState({loading: true})
        })
      })
    }
    catch(error){
      console.log(error);
    }

  }
  componentDidMount(){
    console.log(this.props.token);
    this.fetchData();
  }
  render(){

    return (

      <div>
        <div className="userProfile_container">
        {this.state.loading &&
          <div className="userProfile_box">

            <div className="userProfile_box_header">
              <div className="userProfile_box_header_datos">
                <p className="userProfile_box_header_datos-conf">Configuración</p>
                <p className="userProfile_box_header_datos-user">Usuario</p>
                <h2>{cookies.get('user')} {cookies.get('lastName')}</h2>
              </div>
              <div className="userProfile_box_header_btn">
                <NavLink to="/profile/edit" style={{textDecoration: "none"}}>
                  <button> Editar <img src={edit}/></button>
                </NavLink>
              </div>
            </div>

            <div className="userProfile_box_contenido">

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Nombre</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.name}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Apellido</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.lastName}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Razón Social</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.businessName}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> RUC</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.ruc}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Establecimiento</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.stablishment}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Dirección del Establecimiento</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.stablishmentDirection}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Teléfono del Establecimiento</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.stablishmentTelephone}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Código del Establecimiento</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.stablishmentCode}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Teléfono</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.telephone}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Célular</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.cellphone}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Dirección</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.direction}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Departamento</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.department}</p>
              </div>

              <div className="userProfile_box_contenido_bloque">
                <p className="userProfile_box_contenido_bloque_label"> Email</p>
                <p className="userProfile_box_contenido_bloque_value"> {this.state.email}</p>
              </div>

            </div>
            <hr/>
            <div className="cajasCheckbox">
              <p>Notificaciones habilitadas:</p>
              <br/>

              <input
                className="checkbox"
                type="checkbox"
                id='Whatsapp'
                name='Whatsapp'
                autoComplete="on"
                checked={this.state.whatsappNotification}
                onChange={e => {this.setState({whatsappNotification: !e.target.checked})}}
                disabled
              /><label class="register_checkbox_label">Whatsapp</label><br/>


              <br/>

              <input
                className="checkbox"
                type="checkbox"
                id='Mail'
                name='Mail'
                autoComplete="on"
                checked={this.state.emailNotification}
                onChange={e => {this.setState({emailNotification: !e.target.checked})}}
                disabled
              /><label class="register_checkbox_label">Mail</label><br/>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>
          </div>

        }

        </div>
      </div>
    )
  }
}

export default UserProfile;
