import React from 'react';
import { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import filterIcon from "../img/filter.svg";
import filterIconOn from "../img/filter-on.svg";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function Filter(props){

  const [filtered, setFiltered] = React.useState(false);
  const [fur, setFur] = React.useState(cookies.get("fur")?cookies.get("fur"):"");
  const [race, setRace] = React.useState(cookies.get("race")?cookies.get("race"):"");
  const [category, setCategory] = React.useState(cookies.get("category")?cookies.get("category"):"");
  const [minvalue, setMinvalue] = React.useState('');
  const [maxvalue, setMaxvalue] = React.useState('');

  useEffect(() => {
      filter();
  }, []);

  const handleFur = (event) => {
   setFur(event.target.value);
   cookies.set('fur', event.target.value, { path: '/' });
  };

  const handleRace = (event) => {
   setRace(event.target.value);
   cookies.set('race', event.target.value, { path: '/' });
  };

  const handleCategory = (event) => {
   setCategory(event.target.value);
   cookies.set('category', event.target.value, { path: '/' });
  };

  const handleMinvalue = (event) => {
   setMinvalue(event.target.value);
  };

  const handleMaxvalue = (event) => {
   setMaxvalue(event.target.value);
  };

  function filter(e) {
    if(e) {
      e.preventDefault();
    }

    props.filter(fur, race, category, minvalue, maxvalue, true)
    setFiltered(true);
    window.scrollTo(0, 0);
  }

  function filterClean(e) {
		e.preventDefault()
		if(cookies.get("fur") !== undefined ||
			cookies.get("race") !== undefined ||
			cookies.get("category") !== undefined) {

			props.filter();
			setFiltered(false);
			setFur("");
			cookies.remove('fur', { path: '/' });
			setRace("");
			cookies.remove('race', { path: '/' });
			setCategory("");
			cookies.remove('category', { path: '/' });
			setMinvalue("");
			setMaxvalue("");
			window.scrollTo(0, 0);
		}
  }

  function openFilter() {
    console.log("s");
    var filterBlock = document.getElementById('filter');
    filterBlock.classList.toggle("filterHide")
  }

  return(

    <div className="invernada_layout_filters filterHide" id="filter">
      <form className="filter" >
        <div className="filter_group">

          <div  className="filter_input">
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">
                Pelajes
              </InputLabel>

              <Select
              labelId="demo-simple-select-label"
              id="Select-fur"
              value={fur}
              label="Pelaje"
              onChange={handleFur}
              style={{top: "220px !important"}}>
                <MenuItem value="">
                  <em>ninguno</em>
                </MenuItem>
								{ props.fur && (
									props.fur.map((fur, i)=>(
										<MenuItem value={fur} key={i}>
											{fur}
										</MenuItem>
									))
								)}
              </Select>
            </FormControl>
          </div>



          <div style={{width: "180px"}} className="filter_input">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Raza
              </InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={race}
              label="Raza"
              onChange={handleRace}>
                <MenuItem value="">
                  <em>ninguno</em>
                </MenuItem>
								{ props.race && (
									props.race.map((race, i)=>(
										<MenuItem value={race} key={i}>
											{race}
										</MenuItem>
									))
								)}
              </Select>
            </FormControl>
          </div>
          <div style={{width: "210px"}} className="filter_input">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Categoria
              </InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Categoria"
              onChange={handleCategory}>
                <MenuItem value="">
                  <em>ninguno</em>
                </MenuItem>
								{ props.category && (
									props.category.map((category, i)=>(
										<MenuItem value={category} key={i}>
											{category}
										</MenuItem>
									))
								)}
              </Select>
            </FormControl>
          </div>


        </div>

        <div className="filter_submit">
					<button
						className="btn btn-ppl btn_filter"
						onClick={filter}>
						Aplicar Filtro
					</button>

					<button
						className="btn btn_cta btn_filter"
						onClick={filterClean}>
						Borrar Filtro
					</button>
        </div>
      </form>

      <div className="filter_openbtn">
        <div >
          {filtered == true
            ?
            <img onClick={openFilter} src={filterIconOn} className="filter_icon"/>
            :
            <img onClick={openFilter} src={filterIcon} className="filter_icon"/>
          }
        </div>
      </div>
    </div>
  )
}
