import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import {FormControl} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const theme = createTheme({
  palette: {
    primary: {
      main: '#19513c'
    },
    secondary: {
      main: '#ff9900'
    },
  },
});

const Newsletter = () => {
	const [email, setEmail] = useState(null);
	const [name, setName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [captchaValidation, setCaptchaValidation] = useState(false);
	const [interests, setInterests] = useState("");
	const [errors, setErrors] = useState("");
	const [open, setOpen] = useState(false);
	let captcha;

	const [options, setOptions] = useState(
		{
			'reporte-diario': false,
			'oferta-invernada': false,
			'compra-venta-campos': false,
			'venta-ganado': false
		}
	);

	const [checking, setChecking] = useState(false);

	function openDialog() {
		setOpen(true);
	}

	function closeDialogWindow() {
		setEmail("");
		setName("");
		setLastName("");
		Object.keys(options).map(option => {
			options[option] = false
		});

		resetCaptcha();
		setOpen(false);
		setChecking(false);
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	function togglePreference(value) {
		let checkBoxClick = options;
		checkBoxClick[value] = !options[value];
		setOptions(checkBoxClick);

		var interestChecked = Object.keys(checkBoxClick).filter(k => { return checkBoxClick[k] ? k : '' });
		let interestData = "";
		interestData += interestChecked + " ";
		setInterests(interestData.trim());
	}

	const resetCaptcha = () => {
		captcha.reset();
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setChecking(true);
		setErrors([]);
		let ignore = false;
		let errorArray = [];
		let countFalse = 0;

		Object.keys(options).map(option => {
			if(options[option] === false) {
				countFalse++;
			}
		});

		if(countFalse >= 4) {
			errorArray.push("Debes chequear al menos una opción de interés");
			setChecking(false);
		}

		if(!captchaValidation) {
			errorArray.push("La validación del captcha es necesario.");
			setChecking(false);
		}

		if(errorArray.length > 0) {
			setErrors(errorArray);
		}

		else {

			axios.post(process.env.REACT_APP_URL_API + configuration.POST_NEWSLETTER, {
				name: name,
				lastName: lastName,
				mail: email,
				text: "",
				interests: interests
				})
				.then((response) => {
					if (!ignore) {
						console.log(response.data);
						if(response.data.status == "error") {
							setChecking(false);
						}

						if(response.data.status == "Ok") {
							openDialog();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	const handleName = (event) => {
		setName(event.target.value);
	}

	const handleLastName = (event) => {
		setLastName(event.target.value);
	}

	const handleEmail = (event) => {
		setEmail(event.target.value);
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
		}
		console.log("Captcha value:", value);
	}

	useEffect(() => {
		console.log(`Captcha token updated: ${captchaValidation}`);
		}, [captchaValidation]);

	return (
		<div className="general-container">
			<div className="properties-container">
				<h1 className="normal-top-title">SUSCRIPCIÓN AL BOLETÍN</h1>
				{ errors.length > 0 ?
					<ul
						style={{
							backgroundColor: "#ff000033",
							listStyle: 'none',
							padding: '17px',
							borderRadius: '8px',
							marginBottom: '1rem'
						}}
						>
						{ Object.values(errors).map(error => (
							<li>{ error }</li>
						))}
					</ul>
					: null
				}

				<ThemeProvider theme={theme}>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={ (e) => { handleSubmit(e) }}
						>
						<TextField
							className="input-newsletter"
							color={`primary`}
							fullWidth
							id="outlined-basic"
							required
							value={ name || "" }
							onChange={(event) => { handleName(event) }}
							label="Nombre" variant="standard" />

						<TextField
							className="input-newsletter"
							required
							fullWidth
							id="outlined-basic"
							label="Apellido"
							value={ lastName || "" }
							onChange={(event) => { handleLastName(event) }}
							variant="standard" />

						<TextField
							className="input-newsletter"
							type="mail"
							fullWidth
							required
							id="outlined-basic"
							label="Correo Electrónico"
							value={ email || "" }
							onChange={(event) => { handleEmail(event) }}
							variant="standard" />

						<FormControl required>
							<FormGroup className="select-group-newsletter">
								<p>Temas de su interés: </p>
								<FormControlLabel
									value="reporte-diario"
									onChange={(event) => togglePreference(event.target.value)}
									control={<Checkbox checked={ options['reporte-diario'] ? true : false } />}
									label="Recibir reporte diario de precios de ferias de consumo" />

								<FormControlLabel
									value="oferta-invernada"
									onChange={(event) => togglePreference(event.target.value)}
									control={<Checkbox checked={ options['oferta-invernada'] ? true : false } />}
									label="Recibir oferta de invernada" />

								<FormControlLabel
									value="compra-venta-campos"
									onChange={(event) => togglePreference(event.target.value)}
									control={<Checkbox checked={ options['compra-venta-campos'] ? true : false } />}
									label="Compra y venta de campos" />

								<FormControlLabel
									value="venta-ganado"
									onChange={(event) => togglePreference(event.target.value)}
									control={<Checkbox checked={ options['venta-ganado'] ? true : false } />}
									label="Venta de ganado" />
							</FormGroup>
						</FormControl>

						<div style={{ marginTop: '1.3rem' }}>
							<ReCAPTCHA
								ref={ (r) => setCaptchaRef(r) }
								sitekey="6LcmvCIkAAAAAAY9wLH-OCvLuZ8kIEl6PkcV4BaO"
								onChange={onChangeRecaptcha}
								/>
						</div>

						<div className="align-center">
							<button
								className="button-submit"
								disabled={ checking ? true : false }
								type="submit">Suscribirme</button>
						</div>
					</Box>
				</ThemeProvider>
			</div>

			<Dialog
				open={open}
				onClose={closeDialogWindow}
				aria-labelledby="success-dialog-title"
				aria-describedby="success-dialog-description"
				>

				<DialogTitle className="dialogTitle" id="alert-dialog-title">
					{ "Confirmación de suscripción" }
				</DialogTitle>
				<DialogContent style={{ paddingBottom: 0 }}>
					<DialogContentText id="alert-dialog-description" className="alert-dialog-description">
						Muchas gracias por suscribirte a nuestro boletín informativo!
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialogActionWrapper">
					<Button className="dialogBtn submitDialogButton" onClick={closeDialogWindow} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default Newsletter;
