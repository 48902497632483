import React, { useState, useEffect } from 'react';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import configuration from '../config/configuration.json';
import ConsumoTable from '../components/ConsumoTable';
import ConsumoMobile from '../components/ConsumoMobile'
import Preloader from '../components/Preloader';

const ConsumoLiquidacionesInforme = () => {
	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '46.25em' });
	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState([]);
	const user = window.location.href.split('/')[5];
	const feriaId = window.location.href.split('/')[7];
	const feriaDate = window.location.href.split('/')[9];

	function formatDate(date) {
		let newDate = new Date(date);
		let options = { day: "numeric", year: "numeric", month: "long" };
		return new Intl.DateTimeFormat("es-AR", options).format(newDate);
	}

	function getDay(date) {
		let newDate = new Date(date);
		let options = { weekday: "long" };
		return new Intl.DateTimeFormat("es-AR", options).format(newDate);
	}

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_SELLER_FERIA + "/" +feriaId+"/"+user)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	const Average = ({ averageData }) => {
		return (
			<table className="customers-average">
				<tr className="backgroundTransparent mobileSpacing">
					<th>Peso Prom.</th>
					<th className="right-mobile">{ averageData.pesoProm.toLocaleString() }</th>
				</tr>
				<tr className="backgroundTransparent mobileSpacing">
					<th>Precio Prom.</th>
					<th className="right-mobile">{ averageData.precioProm.toLocaleString() }</th>
				</tr>
				<tr className="backgroundTransparent mobileSpacing">
					<th>Importe Unit.</th>
					<th className="right-mobile">{ averageData.importeUnitario.toLocaleString() }</th>
				</tr>
			</table>
		)
	}

	return (
		<div className="consumoContainer">
			<div className="consumoContent">
				<ConsumoTopTitle />
				<ConsumoTopButtons active={3} />

				{ !isLoading ?
					<>
						<div className="consumoDataContent">
							<h2 className="mobileSpacing">INFORME DE SU VENTA</h2>
							{ data && (
								<h3 className="noTextDecoration">NOMBRE: { data.sellerName }</h3>
							)}
							<h4>{ `${getDay(feriaDate).toUpperCase()}, ${formatDate(feriaDate).toUpperCase()}`}</h4>
						</div>

						{ data.sellerResumes && (
							data.sellerResumes.map((info, index) => (
								<div key={index}>
									<h2 className="informeHeader">CLASE: { info.clase }</h2>
									{ info.ventas && (
										<div className="contentDataTableContainer" style={{ marginBottom: "5rem" }}>
											{ isMobileToTablet && ( <ConsumoMobile
												calculationType={ "liquidacion" }
												dataTable={ info.ventas } /> )}

											{ isFromTablet && ( <ConsumoTable
												calculationType={ "liquidacion" }
												dataTable={info.ventas} /> )}
											<Average averageData={ info }/>
										</div>
									)}
								</div>
							))
						)}
					</>
					:
					<div className="lottie-loading">
						<Preloader />
					</div>
				}
			</div>
		</div>
	)
}

export default ConsumoLiquidacionesInforme;
