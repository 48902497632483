import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import { NavLink } from 'react-router-dom';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import axios from 'axios';
import Preloader from '../components/Preloader';

const ConsumoEstadisticas = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
		"Septiembre", "Octubre", "Noviembre", "Diciembre"];

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_YEARS_MONTHS_FERIA)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
			<div className="consumoContainer">
				<div className="consumoContent">
					<ConsumoTopTitle />
					<ConsumoTopButtons active={2} />

					<div className="consumoDataContent">
						<h2 className="mobileSpacing">ESTADÍSTICAS SEMANALES Y MENSUALES</h2>
					</div>

					{ !isLoading ?
						data && (
							<div className="consumoEstadisticaYears">
								{ Object.entries(data)
								.sort(([key1, key2]) => { return parseInt(key2) - parseInt(key1) })
								.map(([key, value], index) => (
									<div key={index} className="consumoEstadisticasYear">
										<>
										<p>{ key }</p>
											<div className="consumoEstadisticaMonths">
												{ value.map((v,i) => (
													<NavLink key={i} to={`/consumo/estadisticas/${key}/m/${v}`} className="buttonMonth">
														{ months[v - 1] }
													</NavLink>
												))}
											</div>
										</>
									</div>
								))}
							</div>
						)
						:
						<div className="lottie-loading">
							<Preloader />
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default ConsumoEstadisticas;
