import React from 'react';

function TermsReproductores() {
	return (
		<div className="body-text-wrapper">
			<p className="body-text-p-normal">
				Todas las operaciones de compra-venta se concretarán a través de El Rodeo S.A. por cuyo intermedio y
				en cuyas oficinas se realizarán las liquidaciones y órdenes de entrega de animales.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Forma de Venta
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>Para clientes con carpeta habilitada: 12 cheques sin interés. La primera cuota deberá ser abonada al
				contado junto con la comisión. El saldo en 11 cuotas mensuales y corridas.</li>
				<li>Los clientes sin carpeta habilitada deberán pagar al contado.</li>
				<li>Pagos al contado deberán formalizarse en los próximos 3 días hábiles de concluirse la subasta y tendrán
				10% de descuento.</li>

				<li>La comisión de la firma rematadora es del 5% más IVA y deberá ser abonada al contado.</li>

				<li>El cuidado y piquetaje de los animales adquiridos estarán a cargo de El Rodeo S.A. hasta un tiempo
				máximo de 24 horas posterior al evento. Transcurrido dicho período, la Firma Rematadora se reserva el derecho de mantenerlos donde se encuentren o trasladarlos. En ambos casos correrán por cuenta
				exclusiva del adquiriente los gastos de piquetaje, cuidado y transporte.</li>

				<li>Los animales deberán ser controlados mientras se encuentren en el predio ferial, no aceptándose
				reclamos una vez que los mismos hayan sido retirados.</li>

				<li>Pasadas las 48 horas de haberse finalizado la subasta y al no haberse formalizado la operación El Rodeo
				S.A. podrá dar por desistida la misma (según Código de Comercio).</li>

				<li>El Rodeo S.A. se reserva el derecho de aceptar al comprador.</li>

				<li>Descuentos por las pre-ofertas quedan establecidos de la siguiente manera:
					<ul className="ul-list-text">
						<li>5% por iniciar la puja</li>
						<li>3% por participar en las pujas.</li>
						<li>5% por la pre-oferta ganadora.</li>
						<li>Dichos descuentos no serán acumulables.</li>
					</ul>
				</li>
			</ul>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Condiciones Sanitarias y Reproductivas
				</h2>
			</div>

			<ul className="ul-list-text">
				<li>De acuerdo a las disposiciones de SENACSA todos los animales deberán estar vacunados contra:

					<ul className="ul-list-text">
						<li>Fiebre Aftosa</li>
						<li>Carbunclo bacteriano</li>
						<li>Rabia</li>
						<li>Hembras: Brucelosis entre 6 y 8 meses de edad</li>
						<li>Hembras: Botulismo</li>
						<li>Toros: los mismos deberán presentar su test andrológico en donde se certifique que son aptos para reproducción</li>
						<li>Hembras preñadas: deberán presentar garantía de preñez y comunicación de denuncia de
						servicio por un profesional y podrán ser palpadas por el comprador si así lo requiere.</li>
					</ul>
				</li>
			</ul>
		</div>
	)
}

export default TermsReproductores;

