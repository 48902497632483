import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoteTimerMobile from './LoteTimerMobile';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import Lottie from 'lottie-react';
import loader from '../img/loader.json';

const LoteRepMobile = (props) => {

	const [status, setStatus] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		newState: false,
		showStatus: [],
		buyType: null,
		expanded: false
	});

	useEffect(() => {
		if(props.buyoutPrice > 0 && props.minOfferValue <= 1) {
			setState(prev => { return { ...prev, buyType: 1 }});
		}

		if(props.buyoutPrice > 0 && props.minOfferValue > 1) {
			setState(prev => { return { ...prev, buyType: 0 }});
		}

		if(props.buyoutPrice <= 0 && props.minOfferValue >= 1) {
			setState(prev => { return { ...prev, buyType: 2 }});
		}
	}, []);

	function updateStatus(status) {
		setStatus(status);
		setLoading(false);
	}

	const caracteristicas = [
		{
			id: 1,
			label: "Propietario: ",
			value: props.owner
		},
		{
			id: 2,
			label: "Cantidad: ",
			value: props.animales.length
		},
		{
			id: 3,
			label: "Descripción: ",
			value: props.short
		},
	]

	const populate = (id) => {
		var innerElement = document.getElementById('animalsWrapper'  + id);

		var list = [];
		var multi = false

		for (var i = 0; i < props.animales.length; i++) {
			var caja = [];
			var animal = `
			<div class="lotesBig_card_infoSecundaria_caja_bloque">
			<p class="lotesBig_card_infoSecundaria_caja_bloque_titulo-tabla">
			Animal
			</p>
			<p class="lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla">
			${i + 1}
			</p>
			</div>`

			caja.push(animal);

			for (var j = 0; j < props.animales[i].propiedades.length; j++) {
				var template1 = `
			<div class="lotesBig_card_infoSecundaria_caja_bloque">
				<p class="lotesBig_card_infoSecundaria_caja_bloque_titulo-tabla">
				${props.animales[i].propiedades[j].name}
				</p>
				<p class="lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla">
				${props.animales[i].propiedades[j].value}
				</p>
			</div>
			`
				var template2 = `
			<div class="lotesBig_card_infoSecundaria_caja_bloque">
				<p class="lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla">
				${props.animales[i].propiedades[j].value}
				</p>
			</div>
			`
				var template3 = `
			<div class="lotesBig_card_infoSecundaria_caja_bloque">
				<p class="lotesBig_card_infoSecundaria_caja_bloque_titulo"></p>
				<p class="lotesBig_card_infoSecundaria_caja_bloque_descripcion extended"></p>
			</div>
			`
				if(!i == "a"){
					caja.push(template2);
				}else{
					caja.push(template1);
				}

			}

			caja = caja.join(' ');
			if(multi){
				var cajafull = '<div class="lotesBig_card_infoSecundaria_caja-multi-tabla">' + caja + '</div>';
			}else{
				var cajafull = '<div class="lotesBig_card_infoSecundaria_caja-multi-tabla">' + caja + '</div>';
			}

			list.push(cajafull);
		}

		if(props.short){
			list.push(template3)
		}
		list = list.join(' ');
		innerElement.innerHTML = list;
	}

	const handleChange = (panel) => (event, isExpanded) => {
		if(isExpanded) {
			setState((prev) => ({ ...prev, expanded: panel}))
			populate(props.mi)
		}

		else {
			setState((prev) => ({ ...prev, expanded: false}))
			let element = document.getElementById('animalsWrapper'+props.mi);
			element.innerHTML = '';
		}
	};

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ExpandMoreIcon sx={{ fontSize: '13px' }} />}
			{...props}
			/>
	))(({ theme }) => ({
		color: '#19513C',
		backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(255, 255, 255, 1)',
		flexDirection: 'row',
		'&:before': {
			height: 0
		},
		'&.Mui-expanded': {
			minHeight: '48px'
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: 0
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			marginLeft: '8px'
		},
		'& .MuiAccordionSummary-content': {
			marginRight: theme.spacing(1),
			flexGrow: 'inherit',
		},
		'& .MuiAccordionSummary-content > p': {
			fontSize: '14px',
			fontWeight: 500,
			textDecoration: 'underline',
			textTransform: 'uppercase'
		}
	}));


	return (
		<>
			{ !loading ?
				<div className="lote-wrapper">

					<LoteTimerMobile
						updateStatus={ updateStatus }
						images={ props.imagen }
						videos={ props.video }
						name={ props.name }
						end={ props.end }
						mi={ props.mi }
						loteType={ "reproductores" }
						remated={ props.remated }
						start={ props.start } />
					<hr className="hr-separate" />

					<div className="timer-lote-offer-wrapper">
						<div className="lote-offer-type mobileSpacing">
							<div className={ state.buyType === 1 ? "buy-type-block block-blue" : "buy-type-block block-green" }>
								{ state.buyType === 1 ? "Compra directa" : "Pre-oferta" }
							</div>

						</div>
					</div>

					{ props.minOfferValue > 1 && (
						<div className="lote-last-offer-wrapper">
							<p>ÚLTIMA OFERTA</p>
							<p>{ props.base.toLocaleString('es-AR')} Gs.</p>
						</div>
					)}

					<hr className="hr-separate" />
					<div className="lote-properties">
						{ caracteristicas.map((a, key) => (
							<div key={key} className="property-block">
							<p>{ a.label } <span> { a.value }</span></p>
							</div>
						))}

						{ props.yours ?
							<div className="lote-your-offer">
								<StarIcon />
								Tu Pre-oferta
							</div>
							:
							props.minOfferValue > 1 && (
								<div className="property-block">
									<p className="green-text">{ props.alias ? `Ofertante: ${props.alias}` : "No hay ofertas" }</p>
								</div>
							)
						}
					</div>

					<div className="lote-action-buttons mobileSpacing">
						{ status && (
						<Link to={"/puja/" + props.mi + "/" + props.remated}
							props={{ minOfferIncrement: props.minOffer }}
							className={ status ? "btn-lote btn-lote-offer" : "btn-lote btn-lote-disabled"}>

							{ (props.buyoutPrice > 0 && props.minOfferValue <=1) && ("Comprar") }
							{ (props.buyoutPrice > 0 && props.minOfferValue > 1) && ("Pre-ofertar") }
							{ (props.buyoutPrice <= 0 && props.minOfferValue >= 1) && ("Pre-ofertar") }
						</Link>
						)}

						{ !status && (
							<Link to={"/puja/"+props.mi+"/"+props.remated} className="btn-lote btn-lote-show-more">
								Ver información del lote
							</Link>
						)}
					</div>

					<div className="show-lote-animals">
						<Accordion disableGutters elevation={0} expanded={state.expanded === 'animalAcc'+props.mi} onChange={handleChange('animalAcc'+props.mi)}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={ "animalAcc"+props.mi+"bh-content" }
								id={ "animalAcc"+props.mi+"bh-header"}
								>
								<Typography sx={{ width: '100%', flexShrink: 0 }}>
									Ver animales
								</Typography>
							</AccordionSummary>

							<AccordionDetails className="no-padding">
								<Typography>
									<div className={props.multi ? "lotesBig_card_infoSecundariaTable lotesBig_card_infoSecundaria-multi" : "lotesBig_card_infoSecundariaTable"} id={"animalsWrapper"  + props.mi}>
									</div>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</div>
				</div>
				:
				<div className="lottie-loading">
					<Lottie
						animationData={loader}
						loop={true}
						className="lottie-loading-anim"
					/>
				</div>
			}
		</>
	)
}

export default LoteRepMobile;
