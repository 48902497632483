import React from 'react';
import {useParams, useNavigate, useLocation, Navigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import configuration from '../config/configuration.json'
import Lottie from 'lottie-react';
import loader from '../img/loader.json';

class ForgotPassword extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      userMail: '',
      mailCheck: false,
      btnOffer: false
    }
  }
   onSubmit = async (e) => {
    e.preventDefault();
    this.setState({btnOffer: true})
    var snackbarMsj = document.getElementById('snackbar_msj');
    var snackbarBox = document.getElementById('snackbar_box');

    console.log("enviado");

    e.preventDefault();

    if(true){
      //console.log("Se puede logear");
      try {
        let res = await fetch(process.env.REACT_APP_URL_API + configuration.FORGOT_PASSWORD, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "text/plain"
          },
          body: JSON.stringify({
            mail: this.state.userMail
          }),
        })
        .then((response)=> response.json())
        .then((data)=> {
          this.setState({btnOffer: false})
          if(data.status === 'Ok'){
      //      console.log(data);


            snackbarMsj.innerText = "📩 Mail enviado con exito"
            snackbarBox.classList.add("snackbar-hd");


            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
            },3000)


          }else{
            snackbarMsj.innerText = data.data
            snackbarBox.classList.add("snackbar-hd");
            snackbarBox.classList.add("snackbar-error");


            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
              snackbarBox.classList.remove("snackbar-error");
            },3000)

          }

        })
      } catch (err) {
        console.log(err);
      }
    }else{
      this.setState({btnOffer: false})
      console.log("no se puede logear");
    }


  }
  handlerMail = (e) =>{
    var cajaTextoBtn = document.getElementById('mensajeBtn');
    cajaTextoBtn.innerText = " ";

    this.setState( {userMail: e.target.value} );
    var regEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(e.target.value);


    if(regEx){

      cajaTextoBtn.innerText = " ";
      this.setState({mailCheck: true});
    }else{

      cajaTextoBtn.innerText = "Ingrese un mail válido";
      this.setState({mailCheck: false});
    }
  }
  render(){
    return(
      <div className="form-m">

        <div className="form">
          <img src="./img/cow-log.png" className="form_img"/>
          <form onSubmit={this.onSubmit}>
            <h3>Recuperación de contraseña</h3>
            <div className="form_box">

              <div className="form_box_item">
                <label>Correo Electrónico</label><br/>
                <input
                  type="text"
                  id='mail'
                  name='mail'
                  onChange={this.handlerMail}
                  className="active"
                /><br/>
                <small id="mensajeMail"></small>
              </div>


            <button
              type="submit"
              className={!this.state.btnOffer ? "btn btn_cta" : "btn btn_cta btn-disabled"}
              onClick={this.onSubmit}
              disabled={this.state.btnOffer}
            >
              {!this.state.btnOffer
                ?
                "Enviar"
                :
                <Lottie
                  animationData={loader}
                  loop={true}
                  className="btn-lottie-loading-anim"
                />
              }

            </button>
            <small id="mensajeBtn"></small>

            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ForgotPassword;
