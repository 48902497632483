import React, { useState } from 'react';
import configuration from '../config/configuration.json';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const theme = createTheme({
  palette: {
    primary: {
      main: '#19513c'
    },
    secondary: {
      main: '#ff9900'
    },
  },
});

const ContactUs = () => {
	let captcha;

	const [name, setName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState(null);
	const [text, setText] = useState(null);

	const [captchaValidation, setCaptchaValidation] = useState(false);

	const [errors, setErrors] = useState("");
	const [open, setOpen] = useState(false);
	const [checking, setChecking] = useState(false);

	function openDialog() {
		setOpen(true);
	}

	function handleNumbers(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setPhone(event.target.value);
		}
	}

	function closeDialogWindow() {
		setName(null);
		setLastName(null);
		setPhone("");
		setSubject(null);
		setEmail(null);
		setText(null);

		resetCaptcha();
		setOpen(false);
		setChecking(false);
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	const resetCaptcha = () => {
		captcha.reset();
	}

	const handleSubmit = (e) => {
		const formData = new FormData();

		e.preventDefault();
		setChecking(true);
		setErrors([]);
		let ignore = false;
		let errorArray = [];

		if(!name) {
			errorArray.push("Campo nombre es requerido.");
			setChecking(false);
		}

		if(!lastName) {
			errorArray.push("Campo apellido es requerido.");
			setChecking(false);
		}

		if(!email) {
			errorArray.push("Campo correo electrónico es requerido.");
			setChecking(false);
		}

		if(!phone) {
			errorArray.push("Campo teléfono es requerido.");
			setChecking(false);
		}

		if(!subject) {
			errorArray.push("Campo asunto es requerido.");
			setChecking(false);
		}

		if(!text) {
			errorArray.push("Campo mensaje es requerido.");
			setChecking(false);
		}

		if(!captchaValidation) {
			errorArray.push("La validación del captcha es necesario.");
			setChecking(false);
		}

		if(errorArray.length > 0) {
			setErrors(errorArray);
		}

		else {
			let contentData = [];
			let emails = [];

			contentData = [
				{ "key": "Nombre", "value": name },
				{ "key": "Apellido", "value": lastName },
				{ "key": "Correo Electrónico", "value": email },
				{ "key": "Teléfono", "value": phone },
				{ "key": "Asunto", "value": subject },
				{ "key": "Mensaje", "value": text }
			];

			formData.append("subject", "Contactos");
			formData.append("content", JSON.stringify(contentData));
			formData.append("isForEmail", true);
			formData.append("toEmail", "web-contacto@elrodeo.com.py");
			formData.append("formType", "Contactos");
			formData.append("files", []);

			axios.post(configuration.PANEL_SERVER_URL + configuration.POST_SUBMIT_FORM, formData)
				.then((response) => {
					if (!ignore) {
						if(response.data.status == "error") {
							setChecking(false);
						}

						if(response.data.status == "Ok") {
							setChecking(false);
							openDialog();
						}
					}
				})
				.catch((error) => {
					if(error.name === "AxiosError") {
						errorArray.push("Ocurrió un error al enviar el formulario.");
						setErrors(errorArray);
						setChecking(false);
					}
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
		}
	}

	return (
		<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banners/contactanos.png') } />
			<div className="properties-container">

				{ errors.length > 0 ?
					<>
						<p style={{ marginBottom: '0.5rem' }}>
							<strong>Por favor corrige los siguientes temas: </strong>
						</p>
						<ul
							style={{
								backgroundColor: "#ff000033",
								listStyle: 'none',
								padding: '17px',
								borderRadius: '8px',
								marginBottom: '1rem'
							}}
							>
							{ Object.values(errors).map(error => (
								<li>{ error }</li>
							))}
						</ul>
					</>
					: null
				}

				<ThemeProvider theme={theme}>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={ (e) => { handleSubmit(e) }}
						>
						<TextField
							className="input-newsletter"
							color={`primary`}
							fullWidth
							id="outlined-basic"
							required
							value={ name || "" }
							onChange={(event) => { setName(event.target.value) }}
							label="Nombre" variant="standard" />

						<TextField
							className="input-newsletter"
							required
							fullWidth
							id="outlined-basic"
							label="Apellido"
							value={ lastName || "" }
							onChange={(event) => { setLastName(event.target.value) }}
							variant="standard" />

						<TextField
							className="input-newsletter"
							type="mail"
							fullWidth
							required
							id="outlined-basic"
							label="Correo Electrónico"
							value={ email || "" }
							onChange={(event) => { setEmail(event.target.value) }}
							variant="standard" />

						<TextField
							className="input-newsletter"
							type="text"
							fullWidth
							required
							id="outlined-basic"
							label="Teléfono"
							value={ phone }
							onChange={ handleNumbers }
							variant="standard" />

						<TextField
							className="input-newsletter"
							type="text"
							fullWidth
							required
							id="outlined-basic"
							label="Asunto"
							value={ subject || "" }
							onChange={(event) => { setSubject(event.target.value) }}
							variant="standard" />

						<TextField
							className="input-newsletter"
							type="text"
							multiline
							rows={5}
							fullWidth
							required
							id="outlined-basic"
							label="Mensaje"
							value={ text || "" }
							onChange={(event) => { setText(event.target.value) }}
							variant="standard" />

						<div style={{ marginTop: '1.3rem' }}>
							<ReCAPTCHA
							ref={ (r) => setCaptchaRef(r) }
								sitekey="6LcmvCIkAAAAAAY9wLH-OCvLuZ8kIEl6PkcV4BaO"
								onChange={onChangeRecaptcha}
							/>
						</div>

						<div className="align-center">
							<button
								className="button-submit"
								disabled={ checking ? true : false }
								type="submit">{ checking ? "Enviando..." : "Enviar mensaje" }</button>
						</div>
					</Box>
				</ThemeProvider>
			</div>

			<Dialog
				open={open}
				onClose={closeDialogWindow}
				aria-labelledby="success-dialog-title"
				aria-describedby="success-dialog-description"
				>

				<DialogTitle className="dialogTitle" id="alert-dialog-title">
					{ "Confirmación de envío" }
				</DialogTitle>
				<DialogContent style={{ paddingBottom: 0 }}>
					<DialogContentText id="alert-dialog-description" className="alert-dialog-description">
						Muchas gracias por completar el formulario, nos pondremos en contacto con usted en la brevedad.
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialogActionWrapper">
					<Button className="dialogBtn submitDialogButton" onClick={closeDialogWindow} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ContactUs;
