import React from "react";
import { Navigate } from "react-router-dom";
import {Link} from 'react-router-dom';
import Lottie from 'lottie-react';
import form from '../json/form.json';
import configuration from '../config/configuration.json'
import axios from 'axios';

class Register extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            logged: false,
            mailCheck: true,
            passCheck: false,
            name: "",
            lastName: "",
            businessName: "",
            ruc: "",
            stablishment: "",
            stablishmentDirection: "",
            stablishmentDepartment: "",
            stablishmentTelephone: "",
            stablishmentCode: "",
            telephone: "",
            cellphone: "",
            direction: "",
            department: "",
            email: "",
            password: "",
            whatsappNotification: false,
            emailNotification: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    handlerMail = (e) =>{

        var cajaTextoBtn = document.getElementById('mensajeBtn');
        cajaTextoBtn.innerText = " ";
        this.setState( {email: e.target.value} );
        var regEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(e.target.value);


        var cajaTextoMail = document.getElementById('mensajeEmail')
        if(regEx){
            cajaTextoMail.innerText = " ";
            this.setState({mailCheck: true});
        }else{
            cajaTextoMail.innerText = "Ingrese un mail válido";
            this.setState({mailCheck: false});
        }
    }

    habdlerWhatsappCheckBox = () =>{
        if(this.state.whatsappNotification){
            this.setState({whatsappNotification: false})
        }else{
            this.setState({whatsappNotification: true})
        }
    }

    habdlerMailCheckBox = () =>{
        if(this.state.emailNotification){
            this.setState({emailNotification: false})
        }else{
            this.setState({emailNotification: true})
        }
    }

    handlerPass = (e) =>{
        var cajaTextoBtn = document.getElementById('mensajeBtn');
        cajaTextoBtn.innerText = " ";

        this.setState( {password: e.target.value} );
        var regEx = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(e.target.value);


        var cajaTextoPass = document.getElementById('mensajePassword')
        if(true){
            cajaTextoPass.innerText = " ";
            this.setState({passCheck: true});
        }else{
            cajaTextoPass.innerText = "más de 8 letras, más de 1 dígito, más de 1 mayúscula.";
            this.setState({passCheck: false});
        }
    }
    async onSubmit(e){
        var snackbarMsj = document.getElementById('snackbar_msj');
        var snackbarBox = document.getElementById('snackbar_box');

        console.log(this.state.whatsappNotification);
        console.log(this.state.emailNotification);

        var cajaTextoBtn = document.getElementById('mensajeBtn');
        e.preventDefault();

        if(this.state.mailCheck && this.state.passCheck){
            try {
                let res = await fetch(process.env.REACT_APP_URL_API + configuration.REGISTER, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "accept": "text/plain"
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        lastName: this.state.lastName,
                        businessName: this.state.businessName,
                        ruc: this.state.ruc,
                        stablishment: this.state.stablishment,
                        stablishmentDirection: this.state.stablishmentDirection,
                        stablishmentDepartment: this.state.stablishmentDepartment,
                        stablishmentTelephone: this.state.stablishmentTelephone,
                        stablishmentCode: this.state.stablishmentCode,
                        telephone: this.state.telephone,
                        cellphone: this.state.cellphone,
                        direction: this.state.direction,
                        department: this.state.department,
                        email: this.state.email,
                        status:'ACTIVO',
                        password: this.state.password,
                        whatsappNotification: this.state.whatsappNotification,
                        emailNotification: this.state.emailNotification
                    }),
                })
                    .then((response)=> response.json())
                    .then((data)=> {

                        if(data.status == 'Error'){
                            snackbarMsj.innerText =  data.data
                            snackbarBox.classList.add("snackbar-hd");
                            snackbarBox.classList.add("snackbar-error");
                            setTimeout(()=>{
                                snackbarBox.classList.remove("snackbar-hd");
                                snackbarBox.classList.remove("snackbar-error");
                            },3000)

                        }else{
                            snackbarMsj.innerText =  data.data
                            snackbarBox.classList.add("snackbar-hd");
                            setTimeout(()=>{snackbarBox.classList.remove("snackbar-hd");},3000)

                            const formData = new FormData();
                            let ignore = false;

                            // Send to save as form and send email to manager.
                            let contentData = [];
                            contentData = [
                                { "key": "Nombre", "value": this.state.name },
                                { "key": "Apellido", "value": this.state.lastName },
                                { "key": "Establecimiento", "value": this.state.stablishment },
                                { "key": "RUC", "value": this.state.ruc },
                                { "key": "Correo Electrónico", "value": this.state.email },
                                { "key": "Departamento", "value": this.state.department },
                                { "key": "Celular", "value": this.state.cellphone },
                            ];

                            //formData content for DB table form and send email
                            formData.append("subject", "Registro de usuario");
                            formData.append("content", JSON.stringify(contentData));
                            formData.append("isForEmail", true);
                            formData.append("toEmail", "web-registro@elrodeo.com.py");
                            formData.append("formType", "Registro_usuario");
                            formData.append("files", []);

                            //Save on the DB table forms and send email.
                            axios.post(configuration.PANEL_SERVER_URL + configuration.POST_SUBMIT_FORM, formData)
                                .then((response) => {
                                    if (!ignore) {
                                        if(response.data.status == "error") {
                                            console.error("Ocurrió un error al enviar el correo");
                                        }
                                    }
                                })
                                .catch((error) => {
                                    if(error.name === "AxiosError") {
                                        console.error(error);
                                    }
                                })

                            this.setState({logged: true})
                        }
                    })

            } catch (err) {
                console.error(err);
            }
        }else{
            cajaTextoBtn.innerText = "Complete correctamente los campos de arriba."

        }
    }

    render(){
        return(
            <div className="form-m form-m-register">
                { this.state.logged ? (<Navigate to="/login"/>) : null }
                <div className="form">
                    <Lottie
                        animationData={form}
                        loop={false}
                        className="form_img"
                    />
                    <form onSubmit={this.onSubmit}>
                        <br/>
                        <br/>
                        <h3>Registrate</h3>
                        <div className="form_box">
                            <div className="form_flex">
                                <div>
                                    <div className="form_box_item">
                                        <label>Nombre *</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='name'
                                            name='name'
                                            onChange={(e) => {this.setState({name: e.target.value})}}
                                        />
                                    </div>

                                    <div className="form_box_item">
                                        <label>Apellido *</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='lastName'
                                            name='lastName'
                                            onChange={(e) => {this.setState({lastName: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form_box_item">
                                        <label>Email *</label><br/>
                                        <input
                                            type="email"
                                            className="input_wide"
                                            id='email'
                                            name='email'
                                            autoComplete="on"
                                            onChange={this.handlerMail}
                                        />
                                        <small id="mensajeEmail"></small>
                                    </div>
                                    <div className="form_box_item">
                                        <label>Contraseña *</label><br/>
                                        <input
                                            type="password"
                                            className="input_wide"
                                            id='pass'
                                            name='pass'
                                            autoComplete="on"
                                            onChange={this.handlerPass}
                                        />
                                        <small id="mensajePassword"></small>
                                    </div>
                                </div>
                                <div>
                                    <div className="form_box_item">
                                        <label>RUC *</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='ruc'
                                            name='ruc'
                                            autoComplete="on"
                                            onChange={(e) => {this.setState({ruc: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form_box_item">
                                        <label>Celular *</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='cellphone'
                                            name='cellphone'
                                            autoComplete="on"
                                            onChange={(e) => {this.setState({cellphone: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form_box_item">
                                        <label>Departamento</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='department'
                                            name='department'
                                            autoComplete="on"
                                            onChange={(e) => {this.setState({department: e.target.value})}}
                                        />
                                    </div>
                                    <div className="form_box_item">
                                        <label>Establecimiento</label><br/>
                                        <input
                                            type="text"
                                            className="input_wide"
                                            id='stablishment'
                                            name='stablishment'
                                            autoComplete="on"
                                            onChange={(e) => {this.setState({stablishment: e.target.value})}}
                                        />
                                    </div>






                                </div>
                            </div>

                            <div className="cajasCheckbox">
                                <p>Quiere recibir notificaciones?</p>
                                <br/>

                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id='Whatsapp'
                                    name='Whatsapp'
                                    autoComplete="on"
                                    value='off'
                                    onChange={this.habdlerWhatsappCheckBox}
                                /><label class="register_checkbox_label">Whatsapp</label><br/>


                                <br/>

                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id='Mail'
                                    name='Mail'
                                    autoComplete="on"
                                    value='on'
                                    onChange={this.habdlerMailCheckBox}
                                /><label class="register_checkbox_label">Mail</label><br/>
                            </div>

                        </div>

                        <button type="submit" onClick={this.onSubmit} className="btn btn_cta">Registrate</button>
                        <small id="mensajeBtn"></small>
                    </form>
                </div>
                <p className="form_passwordReset">¿Necesitas migrar tu cuenta anterior? Haga <Link to="/migrar"><span>click aqui</span></Link></p>
            </div>
        );
    }
}

export default Register;
