import React from 'react';

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const ConsumoTable = ({ dataTable, calculationType }) => {

	const cities = [
		 "Asunción",
		 "Concepción",
		 "San Pedro",
		 "Cordillera",
		 "Guairá",
		 "Caaguazú",
		 "Caazapá",
		 "Itapúa",
		 "Misiones",
		 "Paraguarí",
		 "Alto Paraná",
		 "Central",
		 "Ñeembucú",
		 "Amambay",
		 "Canindeyú",
		 "Presidente Hayes",
		 "Boquerón",
		 "Alto Paraguay"
	];

	function roundNumber(number) {
		if(typeof(number) === "number") {
			let rounded = Math.round(number);
			return rounded.toLocaleString();
		}
		else {
			return number;
		}
	}

	const CalculateTotal = ({ type }) => {
		if(type === "cant") {
			let total = 0;
			dataTable.map((data) => {
				Object.entries(data).map(([key, value]) => {
					if(key == "cantidad") {
						total += value;
					}
				})
			})
			return (
				<tr style={{ backgroundColor: "#19513cbd" }}>
					<th style={{ color: "white" }}>CANTIDAD TOTAL</th>
					<th style={{ color: "white" }}> { total }</th>
				</tr>
			)
		}

		if(type === "liquidacion") {
			let cantTotal = 0;
			let pesoTotal = 0;
			let importeTotal = 0;

			dataTable.map((data) => {
				Object.entries(data).map(([key, value]) => {
					if(key == "cantidad") {
						cantTotal += value;
					}
					if(key == "peso") {
						pesoTotal += value;
					}
					if(key == "importe") {
						importeTotal += value;
					}
				})
			})
			return (
				<tr style={{ backgroundColor: "#ffefef" }}>
					<th style={{ color: "#366553" }}>TOTAL</th>
					<th style={{ color: "#366553" }}> { cantTotal }</th>
					<th style={{ color: "#366553" }}> { pesoTotal.toLocaleString() }</th>
					<th></th>
					<th style={{ color: "#366553" }}> { importeTotal.toLocaleString() }</th>
				</tr>
			)
		}
	}

	return (
		<>
			{ dataTable.length > 0 ?
				<table className="customers">
					<tbody>
						<tr className="backgroundTransparent">
							{ Object.keys(dataTable[0]).map((key, index) => (
								<th key={ index }>{ capitalizeFirstLetter(key) }</th>
							))}
						</tr>

						{ dataTable.map((data, dataIndex) => (
							<tr key={ dataIndex }>
								{ Object.entries(data).map(([key, inner], innerIndex) => (
									key == "origen" ?
										<td key={ innerIndex }>{ cities[inner] }</td>
										:
										<td key={ innerIndex }>{ roundNumber(inner) }</td>
								))}
							</tr>
						))}
						<CalculateTotal type={ calculationType } />
					</tbody>
				</table>
			:
			<p className="align-center">No hay información disponible.</p>
			}
		</>
	)
}

export default ConsumoTable;
