import React from 'react';

function TermsConsumo() {
	return (
		<div className="body-text-wrapper">
			<p className="body-text-p-normal">
				Las Ferias de Consumo se realizan de Lunes a Viernes a las 14:30 en el predio de El Rodeo S.A. salvo que se
				comunique lo contrario por los canales de difusión de la empresa.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Gestión de Guías
				</h2>
			</div>

			<p className="body-text-p-normal">
				Las mismas deben ser guías simples con destino a El Rodeo S.A. y finalidad “Feria de Consumo”. El código de Establecimiento es 1110010161.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Orden de entradas de los animales
				</h2>
			</div>

			<p className="body-text-p-normal">
				El mismo se llevará a cabo en función al horario de llegada de las cargas,
				entrando en el mismo orden que se hayan recibido los animales en el predio.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Comisión al vendedor
				</h2>
			</div>

			<p className="body-text-p-normal">
				La comisión al vendedor será de 1,65% IVA incluido, a descontarse del valor de la
				liquidación por la venta de los animales.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Comisión al comprador
				</h2>
			</div>

			<p className="body-text-p-normal">
				La comisión al comprador será de 3,5% IVA incluido y deberá ser pagadero al contado una vez finalizada la operación de compra-venta de animales.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Pago al vendedor
				</h2>
			</div>

			<p className="body-text-p-normal">
				El mismo se realizará por transferencia bancaria en el plazo de 1 día hábil de finalizada la feria y concretada la operación de compra-venta de los animales.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/hands_shake.png') } />

				<h2 className="body-text-titles">
					Cuidado y Piquetaje
				</h2>
			</div>

			<p className="body-text-p-normal">
				El mismo estará a cargo de El Rodeo S.A. desde la recepción de los animales hasta las 24 horas del día de la feria. Posterior a eso queda a cuenta del comprador el cuidado y piquetaje de los mismos no aceptándose reclamos pasadas las 24 horas del día y posterior al retiro de los animales.
			</p>

			<div className="body-text-title-block">
				<img src={ require('../img/logo_small.png') } />

				<h2 className="body-text-titles">
					EL RODEO
				</h2>
			</div>

			<p className="body-text-p-normal">
				El Rodeo S.A. se reserva el derecho de aceptar al comprador.
			</p>

			<p className="body-text-p-normal">
				El Rodeo S.A. se compromete a acompañar al cliente en la solución de cualquier trámite con la COLCAT o
				SENACSA que esté a su alcance para favorecer la operación de compra-venta durante la feria.
			</p>
		</div>
	)
}

export default TermsConsumo;
