import React from "react";
import { useState } from "react";
import {Navigate} from 'react-router-dom';
import configuration from '../config/configuration.json'



class PasswordChange extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      logged: false,
      oldPassword: '',
      newPassword: '',
      migred: false,

    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  handlerPass = (e) =>{

    this.setState( {newPassword: e.target.value} );
    //var regEx = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(e.target.value);

    //var cajaTextoPass = document.getElementById('mensajePassword');
    //cajaTextoPass.innerText = " ";
    this.setState({passCheck: true});
    /*
    if(true){
      cajaTextoPass.innerText = " ";
      this.setState({passCheck: true});
    }else{
      cajaTextoPass.innerText = "más de 8 letras, más de 1 dígito, más de 1 mayúscula.";
      this.setState({passCheck: false});
    }
    */
  }
  async onSubmit(e){
    var snackbarBox = document.getElementById('snackbar_box');
    var snackbarMsj = document.getElementById('snackbar_msj');
  //  console.log("on submit");
    e.preventDefault();
  //  console.log(this.state.passCheck);
  /*
    if(this.state.passCheck == false){
  //    console.log("no pass");
      return null

    }
    */
    try {

        let res = await fetch(process.env.REACT_APP_URL_API + configuration.PASSWORD_UPDATE, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "accept": "text/plain",
            'Authorization': 'Bearer ' + this.props.token
          },
          body: JSON.stringify({
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword
          }),
        })
        .then((response)=> response.json())
        .then((data)=> {
          console.log(data);
          if(data.status === 'Ok'){
    //        console.log(data);
            mensajeInfo(data.data)
            //// meter mensaje
            //// redireccionar
            this.setState({migred: true})
          }else{
            mensajeInfo(data.data)

          }

        })
   } catch (err) {
     console.log("error");
     console.log(err);

   }

    function mensajeInfo(msj, error){
      snackbarMsj.innerText =  msj;
      snackbarBox.classList.add("snackbar-hd");
      if(error){
        snackbarBox.classList.add("snackbar-error");
      }
      setTimeout(()=>{
        snackbarBox.classList.remove("snackbar-hd");
        snackbarBox.classList.remove("snackbar-error");
      },3000)
    }
  }
  render(){
    return(
      <div className="form-m">

      {this.state.migred
        ?
        (<Navigate to="/"/>)
        :
        null
      }


      <div className="form">
        <img src="./img/cow-reg.png" className="form_img"/>
        <form>
          <h3>Cambiar contraseña</h3>
            <div className="form_box">

              <div className="form_box_item">
                <label>Contraseña antigua</label>
                <input
                  type="password"
                  id='Oldpassword'
                  name='Oldpassword'
                  autoComplete="on"
                  onChange={(e) => {this.setState({oldPassword: e.target.value})}}
                />
                <small id="mensajeMail"></small>
              </div>

              <div className="form_box_item">
                <label>Nueva contraseña</label>
                <input
                  type="password"
                  id='newPassword'
                  name='newPassword'
                  autoComplete="on"
                  onChange={(e) => {this.setState({newPassword: e.target.value})}}
                />

              </div>

            </div>

          <button type="button" onClick={this.onSubmit} className="btn btn_cta">
            Cambiar contraseña
          </button>

        </form>
        </div>
      </div>
    );
  }
}

export default PasswordChange;
