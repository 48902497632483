import React from 'react';

const CalculateTotal = ({ dataTable=[], calculationType="" }) => {
	if(calculationType === "cant") {
		let total = 0;
		dataTable.map((data) => {
			Object.entries(data).map(([key, value]) => {
				if(key == "cantidad") {
					total += value;
				}
			})
		})
		return (
			<div className="amount-total-container bg-green-totals">
				<h2>TOTALES</h2>
				<div className="consumoMobileContent">
					<p>Cantidad</p>
					<p>{ total }</p>
				</div>
			</div>
		)
	}

	if(calculationType === "liquidacion") {
		let cantTotal = 0;
		let pesoTotal = 0;
		let importeTotal = 0;

		dataTable.map((data) => {
			Object.entries(data).map(([key, value]) => {
				if(key == "cantidad") {
					cantTotal += value;
				}
				if(key == "peso") {
					pesoTotal += value;
				}
				if(key == "importe") {
					importeTotal += value;
				}
			})
		})
		return (
			<div
				className="amount-total-container bg-pink-totals">
				<h2>TOTALES</h2>
				<div className="consumoMobileContent">
					<p>Cantidad</p>
					<p>{ cantTotal }</p>
				</div>

				<div className="consumoMobileContent">
					<p>Peso</p>
					<p>{ pesoTotal.toLocaleString() }</p>
				</div>

				<div className="consumoMobileContent">
					<p>Importe</p>
					<p>{ importeTotal.toLocaleString() }</p>
				</div>
			</div>
		)
	}
}

export default CalculateTotal;
