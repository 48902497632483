import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import configuration from '../config/configuration.json';
import axios from 'axios';
import cookie from 'js-cookie';
import Preloader from '../components/Preloader';

const OffersHistory = () => {
	if(!cookie.get('token')) {
		window.location.href = '/login';
	}

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	function formatLocaleDate(date) {
		let newDate = new Date(date);
		return newDate.toLocaleDateString();
	}

	function formatLocaleTime(date) {
		let newDate = new Date(date);
		return newDate.toLocaleTimeString();
	}

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.post(process.env.REACT_APP_URL_API + configuration.POST_USER_OFFERS, {
			},
				{
					headers: {
						"Authorization": `Bearer ${cookie.get('token')}`
					}
			})
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<div className="lotes-container">
			<div className="offer-history-container">
				<h1>HISTORIAL DE OFERTAS REPRODUCTORES</h1>

				{ !isLoading ?
					<div className="list-container">
						{ data.rematesOffers && (
							data.rematesOffers.length > 0 ?
								data.rematesOffers.map(remate => (
									<div className="list-block-container">
										<h3>{ remate.remateName }</h3>

										{ remate.loteOffers && (
											<div className="list-inner-container">
												{ remate.loteOffers.length > 0 ?
													remate.loteOffers.map((lote, index) => (
														<div className="list-inner-block-wrapper">
															<div className="top-block-offer-history">
																<p>LOTE: { lote.loteName }</p>
																<NavLink to={`/historial-oferta/${remate.remateId}/${lote.loteName}`}>Ver todas</NavLink>
															</div>
															{ lote.ofertas && (
																lote.ofertas.length > 0 ?
																	lote.ofertas.map((loteOffer, index) => (
																		index === 0 ?
																			<div className="bottom-block-offer-history">
																				<div>
																				<p>Últimas ofertas</p>
																					<p>{ loteOffer.offer.toLocaleString() } Gs.</p>
																				</div>

																				<div>
																					<p>Hora</p>
																					<p className="color-orange">{ formatLocaleTime(loteOffer.createdAt) } Hs</p>
																				</div>

																				<div>
																					<p style={{ textAlign: 'right' }}>Fecha</p>
																					<p>{ formatLocaleDate(loteOffer.createdAt) }</p>
																				</div>
																			</div>
																		: null
																	))
																: null
															)}
														</div>
													))
													:
													null
												}
											</div>
										)}
									</div>
								))
							: null
						)}
					</div>
					:
					<div className="lottie-loading">
						<Preloader />
					</div>
				}
			</div>
		</div>
	)
}

export default OffersHistory;
