import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";


import { FreeMode, Navigation, Thumbs } from "swiper";

const BannerImage = (props) => {
	const [state, setState] = useState({
		index: 0,
		open: false
	})

	return (
		<>
			<Swiper
				style={{
					"--swiper-navigation-color": "#212121",
					"--swiper-pagination-color": "#212121",
				}}
				spaceBetween={10}
				navigation={true}
				modules={[FreeMode, Navigation, Thumbs]}
				className="pages-rep-slider"
				>

				{ props.images.map((image, key) => (
					<SwiperSlide key={key}>
						<img src={ image } onClick={() => setState({ open: true, index: key })} />
					</SwiperSlide>
				))}
			</Swiper>

			{state.open && (
				<Lightbox
					mainSrc={props.images[state.index]}
					nextSrc={props.images[(state.index + 1) % props.images.length]}
					prevSrc={props.images[(state.index + props.images.length - 1) % props.images.length]}
					onCloseRequest={() => setState({ isOpen: false })}
					onMovePrevRequest={() =>
						setState(prev => ({ ...prev, index: (state.index + props.images.length - 1) % props.images.length }))
					}
					onMoveNextRequest={() =>
						setState(prev => ({ ...prev, index: (state.index + 1) % props.images.length }))
					}
				/>
			)}
		</>
	)
}

export default BannerImage;
