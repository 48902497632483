import React, { useState } from 'react';
import configuration from '../config/configuration.json';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const theme = createTheme({
	palette: {
		primary: {
			main: '#19513c'
		},
		secondary: {
			main: '#ff9900'
		},
	},
});

const FrigorificoGancho = () => {
	const [nameContact, setNameContact] = useState(null);
	const [phoneContact, setPhoneContact] = useState("");
	const [nameStablishment, setNameStablishment] = useState(null);
	const [truckQty, setTruckQty] = useState("");

	const [captchaValidation, setCaptchaValidation] = useState(false);
	const [errors, setErrors] = useState("");
	const [open, setOpen] = useState(false);

	const [checking, setChecking] = useState(false);

	const [category, setCategory] = useState("Toros");
	const [trazado, setTrazado] = useState("No");
	const [foodType, setFoodType] = useState("Confinamiento");
	const [firstQty, setFirstQty] = useState("");
	const [secondQty, setSecondQty] = useState("");

	const [showSecondQty, setShowSecondQty] = useState(false);

	const [firstQtyText, setFirstQtyText] = useState("Toros");
	const [secondQtyText, setSecondQtyText] = useState("");

	const categories = [
		"Toros",
		"Vacas",
		"Novillos / Toros",
		"Novillos / Vacas",
		"Novillos / Vaquillas",
		"Toros / Vaquillas",
		"Toros / Vacas",
		"Vaquillas / Vacas"
	];

	let captcha;

	function openDialog() {
		setOpen(true);
	}

	function handleCategories(event) {
		let value = event.target.value;

		if(value.indexOf("/") > -1) {
			let splitString = value.split("/");
			setFirstQtyText(splitString[0]);
			setSecondQtyText(splitString[1]);
			setShowSecondQty(true);
		}
		else {
			setShowSecondQty(false);
			setFirstQtyText(value);
			setSecondQtyText("");
		}

		setCategory(event.target.value);
	}

	function closeDialogWindow() {
		setNameContact(null);
		setPhoneContact("");
		setNameStablishment(null);
		setShowSecondQty(false);
		setFirstQty("");
		setTruckQty("");
		setSecondQty("");
		setCategory("Toros");
		setSecondQtyText("");
		setFirstQtyText("Toros");

		resetCaptcha();
		setOpen(false);
		setChecking(false);
	}

	function handlePhoneContact(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setPhoneContact(event.target.value);
		}
	}

	function handleFirstQty(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setFirstQty(event.target.value);
		}
	}

	function handleSecondQty(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setSecondQty(event.target.value);
		}
	}

	function handleTruckQty(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setTruckQty(event.target.value);
		}
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	const resetCaptcha = () => {
		captcha.reset();
	}

	const handleSubmit = (e) => {

		const formData = new FormData();
		e.preventDefault();
		setChecking(true);
		setErrors([]);
		let ignore = false;
		let errorArray = [];

		if(!captchaValidation) {
			errorArray.push("La validación del captcha es necesario.");
			setChecking(false);
		}

		if(firstQty === null && firstQty === "") {
			errorArray.push("Debes cargar la cantidad de animales.");
			setChecking(false);
		}

		if(showSecondQty) {
			if(secondQty === null && secondQty === "") {
				errorArray.push("Debes cargar la cantidad de animales.");
				setChecking(false);
			}
		}

		if(truckQty === "") {
			errorArray.push("Debes cargar la cantidad de camiones.");
			setChecking(false);
		}

		if(nameContact === null || nameContact === "") {
			errorArray.push("Debes cargar el nombre de contacto.");
			setChecking(false);
		}

		if(phoneContact === "") {
			errorArray.push("Debes cargar el número de contacto.");
			setChecking(false);
		}

		if(nameStablishment === null || nameStablishment === "") {
			errorArray.push("Debes cargar el nombre del establecimiento.");
			setChecking(false);
		}

		if(errorArray.length > 0) {
			setErrors(errorArray);
		}

		else {
			let contentData = [];
			if(secondQty !== null) {
				contentData = [
					{ "key": "Categoria", "value": category },
					{ "key": `Cantidad ${firstQtyText}`, "value": firstQty },
					{ "key": `Cantidad ${secondQtyText}`, "value": secondQty },
					{ "key": "Trazado", "value": trazado },
					{ "key": "Tipo Alimentación", "value": foodType },
					{ "key": "Cantidad de camiones", "value": truckQty },
					{ "key": "Nombre de contacto", "value": nameContact },
					{ "key": "Número de contacto", "value": phoneContact },
					{ "key": "Nombre de establecimiento", "value": nameStablishment }
				];
			}
			else {
				contentData = [
					{ "key": "Categoria", "value": category },
					{ "key": `Cantidad ${firstQtyText}`, "value": firstQty },
					{ "key": "Trazado", "value": trazado },
					{ "key": "Tipo Alimentación", "value": foodType },
					{ "key": "Cantidad de camiones", "value": truckQty },
					{ "key": "Nombre de contacto", "value": nameContact },
					{ "key": "Número de contacto", "value": phoneContact },
					{ "key": "Nombre de establecimiento", "value": nameStablishment }
				];
			}

			formData.append("subject", "Frigorifico Gancho");
			formData.append("content", JSON.stringify(contentData));
			formData.append("isForEmail", true);
			formData.append("toEmail", "web-gancho@elrodeo.com.py");
			formData.append("formType", "Frigorifico_Gancho");
			formData.append("files", []);

			axios.post(configuration.PANEL_SERVER_URL + configuration.POST_SUBMIT_FORM, formData)
				.then((response) => {
					if (!ignore) {
						if(response.data.status == "error") {
							setChecking(false);
						}

						if(response.data.status == "Ok") {
							setChecking(false);
							openDialog();
						}
					}
				})
				.catch((error) => {
					if(error.name === "AxiosError") {
						errorArray.push("Ocurrió un error al enviar el formulario.");
						setErrors(errorArray);
						setChecking(false);
					}
					//console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
		}
	}

	return (
		<>
			{ errors.length > 0 ?
				<ul
					style={{
						backgroundColor: "#ff000033",
						listStyle: 'none',
						padding: '17px',
						borderRadius: '8px',
						marginBottom: '1rem'
					}}
					>
					{ Object.values(errors).map(error => (
						<li>{ error }</li>
					))}
				</ul>
				: null
			}

			<ThemeProvider theme={theme}>
				<Box
					component="form"
					onSubmit={(e) => { handleSubmit(e) }}
					autoComplete="off"
					>

					<div className={ showSecondQty ? "block-selects-wrapper"
							: "block-selects-wrapper block-selects-two-frags" }>

						<FormControl variant="standard">
							<InputLabel id="category-select-label">Categoria</InputLabel>
							<Select
								labelId="category-select-label"
								id="category-select"
								value={ category }
								onChange={(e) => { handleCategories(e) }}
								autoWidth
								label="Categoria"
								>
								{ categories.map(category => (
									<MenuItem value={category}>{ category }</MenuItem>
								))}
							</Select>
						</FormControl>

						<TextField
							className="input-newsletter"
							required
							color={`primary`}
							fullWidth id="outlined-basic"
							value={ firstQty}
							onChange={ handleFirstQty }
							label={`Cantidad de ${firstQtyText}`} variant="standard" />

						{ showSecondQty && (
							<TextField
								className="input-newsletter"
								required
								color={`primary`}
								fullWidth id="outlined-basic"
								value={ secondQty}
								onChange={ handleSecondQty}
								label={`Cantidad de ${secondQtyText}`} variant="standard" />
						)}

					</div>

					<div className="block-selects-wrapper">
						<FormControl variant="standard">
							<InputLabel id="trazado-select-label">Trazado</InputLabel>
							<Select
								labelId="trazado-select-label"
								id="trazado-select"
								value={ trazado || "" }
								onChange={(e) => { setTrazado(e.target.value) }}
								autoWidth
								required
								label="Trazado"
								>
								<MenuItem value="No">No</MenuItem>
								<MenuItem value="Si">Si</MenuItem>
							</Select>
						</FormControl>

						<FormControl variant="standard">
							<InputLabel id="confinamiento-select-label">Tipo de alimentación</InputLabel>
							<Select
								labelId="confinamiento-select-label"
								id="confinamiento-select"
								value={ foodType || "" }
								onChange={(e) => { setFoodType(e.target.value) }}
								autoWidth
								required
								label="Confinamiento"
								>
								<MenuItem value="Confinamiento">Confinamiento</MenuItem>
								<MenuItem value="Pastura">Pastura</MenuItem>
								<MenuItem value="Campo Natural">Campo Natural</MenuItem>
							</Select>
						</FormControl>

						<TextField
							className="input-newsletter"
							required
							color={`primary`}
							fullWidth id="outlined-basic"
							value={ truckQty }
							onChange={ handleTruckQty }
							label="Cantidad de camiones" variant="standard" />
					</div>

					<TextField
						className="input-newsletter"
						color={`primary`}
						required
						fullWidth id="outlined-basic"
						value={ nameContact || "" }
						onChange={(e) => { setNameContact(e.target.value) }}
						label="Nombre de contacto" variant="standard" />

					<TextField className="input-newsletter"
						fullWidth
						id="outlined-basic"
						required
						label="Número de contacto"
						value={ phoneContact }
						onChange={ handlePhoneContact }
						variant="standard" />

					<TextField
						className="input-newsletter"
						type="mail"
						fullWidth
						id="outlined-basic"
						label="Nombre de establecimiento"
						required
						value={ nameStablishment || "" }
						onChange={(e) => { setNameStablishment(e.target.value) }}
						variant="standard" />

					<div style={{ marginTop: '1.3rem' }}>
						<ReCAPTCHA
							ref={ (r) => setCaptchaRef(r) }
							sitekey="6LcmvCIkAAAAAAY9wLH-OCvLuZ8kIEl6PkcV4BaO"
							onChange={onChangeRecaptcha}
						/>
					</div>

					<div className="align-center">
						<button
							className="button-submit"
							disabled={ checking ? true : false }
							type="submit">{ checking ? "Enviando..." : "Enviar" }</button>
					</div>
				</Box>
			</ThemeProvider>

			<Dialog
				open={open}
				onClose={closeDialogWindow}
				aria-labelledby="success-dialog-title"
				aria-describedby="success-dialog-description"
				>

				<DialogTitle className="dialogTitle" id="alert-dialog-title">
					{ "Confirmación de envío" }
				</DialogTitle>
				<DialogContent style={{ paddingBottom: 0 }}>
					<DialogContentText id="alert-dialog-description" className="alert-dialog-description">
						Muchas gracias por completar el formulario, nos pondremos en contacto con usted en la brevedad.
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialogActionWrapper">
					<Button className="dialogBtn submitDialogButton" onClick={closeDialogWindow} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default FrigorificoGancho;
