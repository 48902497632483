import React from 'react';
import { NavLink } from 'react-router-dom';

const ConsumoTopButtons = (props) => {
	return (
		<div className="consumoButtonsTop mobileSpacing">
			<NavLink to="/consumo/precios-anteriores" className={ props.active == 1 ? "consumoButtonTop active" : "consumoButtonTop" }>
				Precios años anteriores
			</NavLink>
			<NavLink to="/consumo/estadisticas" className={ props.active == 2 ? "consumoButtonTop active" : "consumoButtonTop" }>
				Estadísticas sem. y mens.
			</NavLink>
			<NavLink to="/consumo/liquidaciones" className={ props.active == 3 ? "consumoButtonTop active" : "consumoButtonTop" }>
				Liquidaciones
			</NavLink>
		</div>
	)
}
export default ConsumoTopButtons;
