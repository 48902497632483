import React from 'react';





class Lightbox extends React.Component {
  close = () =>{
    //console.log('closes');
    var id = document.getElementById('lightbox_img');
    id.classList.remove('displayBlock');
  }
  render() {

    return(
      <div className="lb" id="lightbox_img">
      <button className="lightbox_btn" onClick={this.close}>x</button>
      <div className="lightbox" >

        <div className='lightbox_card' >

          <div className='lightbox_card_img'>

            <img src={this.props.img} />
          </div>
        </div>
      </div>


      </div>

    )
  }
}

export default Lightbox;
