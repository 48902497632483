import React from "react";
import { useState } from "react";
import {Navigate} from 'react-router-dom';
import configuration from '../config/configuration.json'

class Migrar extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      logged: false,
      userName: "",
      userMail: "",
      password: "",
      token: "",
      mailCheck: true,
      passCheck: true,
      migred: false
    }
    this.onSubmit = this.onSubmit.bind(this);
  }
  state = {
    logged: false,
    userName: "",
    userMail: "",
    password: "",
    token: "",
    mailCheck: true,
    passCheck: true
  }
  async onSubmit(e){
    var snackbarMsj = document.getElementById('snackbar_msj');
    var snackbarBox = document.getElementById('snackbar_box');
    e.preventDefault();

    if(true){
      console.log("enviando...");
    try {
        let res = await fetch(process.env.REACT_APP_URL_API + configuration.MIGRATE_USER, {
          method: "POST",
          mode: 'cors',
          cache: 'default',
          headers: {
            "Content-Type": "application/json",
            "accept": "text/plain"
          },
          body: JSON.stringify({
            userMail: this.state.userMail,
            passwordPlain: this.state.password
          }),
        })
        .then((response)=> response.json())
        .then((data)=> {
      //    console.log(data);
          if(data.status === 'Ok'){
            //console.log("logeado");
            snackbarMsj.innerText =  "Migrarción realizada con exito"
            snackbarBox.classList.add("snackbar-hd");
            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
            },3000)



            this.setState({migred:true})


          }else{
            //var cajaTextoBtn = document.getElementById('mensajeBtn');
            //console.log("error de logeo");
          //  console.log(data);

            //cajaTextoBtn.innerText = data.data.errorInfo
            //if(data.data.errorInfo === "User Not Found"){


          //  }

            //if (data.data.errorInfo === "Password is incorrect") {
          //  }
          snackbarMsj.innerText =  data.data.errorInfo
          snackbarBox.classList.add("snackbar-hd");
          snackbarBox.classList.add("snackbar-error");
          setTimeout(()=>{
            snackbarBox.classList.remove("snackbar-hd");
            snackbarBox.classList.remove("snackbar-error");
          },3000)

          }

        })
    } catch (err) {
        console.log(err);
     }
    }else{
      console.log("no se puede logear");
    }


  }
  render(){
    return(
      <div className="form-m">
      { this.state.migred ? (<Navigate to="/login"/>) : null }
      <div className="form">
        <img src="./img/cow-reg.png" className="form_img"/>
        <form>
          <h3>Migrar</h3>
            <div className="form_box">

              <div className="form_box_item">
                <label>Contraseña antigua</label>
                <input
                  type="password"
                  id='password'
                  name='password'
                  autoComplete="on"
                  onChange={(e) => {this.setState({password: e.target.value})}}
                />
                <small id="mensajeMail"></small>
              </div>

              <div className="form_box_item">
                <label>Nuevo Mail</label>
                <input
                  type="text"
                  id='user'
                  name='user'
                  onChange={(e) => {this.setState({userMail: e.target.value})}}
                />
                <small id="mensajeMail"></small>
              </div>

            </div>

          <button type="button" onClick={this.onSubmit} className="btn btn_cta">Migrar</button>
          <small id="mensajeBtn"></small>
        </form>
        </div>
      </div>
    );
  }
}

export default Migrar;
