import React from 'react';
import {useParams, useNavigate, useLocation, Navigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import configuration from '../config/configuration.json'


class ResetPassword extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      resetToken: window.location.href.split('/')[5],
      newPassword: '',
      passCheck: false,
      token: window.location.href.split('/')[5],
    }
  }
   onSubmit = async (e) => {
    var snackbarMsj = document.getElementById('snackbar_msj');
    var snackbarBox = document.getElementById('snackbar_box');

  //  console.log(this.state.token);

    if(!this.state.passCheck){
      return null
    }
    e.preventDefault();
  //  console.log("entro");
    if(this.state.passCheck){
  //    console.log("check");
      try {
        let res = await fetch(process.env.REACT_APP_URL_API + configuration.RESET_PASSWORD, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "text/plain"
          },
          body: JSON.stringify({
            resetToken: this.state.resetToken,
            newPassword: this.state.newPassword
          }),
        })
        .then((response)=> response.json())
        .then((data)=> {
          //console.log(data);


          if(data.status === 'Ok'){
            //console.log(data);
          //  console.log("ok");


            snackbarMsj.innerText = "📩 Password cambiado"
            snackbarBox.classList.add("snackbar-hd");


            setTimeout(()=>{
              snackbarBox.classList.remove("snackbar-hd");
            },3000)


          }else{

          }

        })
      } catch (err) {
        console.log(err);
      }
    }else{
      console.log("no se puede logear");
    }


  }
  handlerPass = (e) =>{
    var cajaTextoBtn = document.getElementById('mensajeBtn');
    cajaTextoBtn.innerText = "a";

    this.setState( {newPassword: e.target.value} );
    var regEx = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(e.target.value);


    //var cajaTextoPass = document.getElementById('mensajePassword')
    if(regEx){
      cajaTextoBtn.innerText = " ";
      this.setState({passCheck: true});
    }else{
      cajaTextoBtn.innerText = "más de 8 letras, más de 1 dígito, más de 1 mayúscula.";
      this.setState({passCheck: false});
    }
  }
  componentDidMount(){
  //  console.log(this.state.token);
  }
  render(){
    return(
      <div className="form-m">

        <div className="form">
          <img src="./img/cow-log.png" className="form_img"/>
          <form>
            <h3>token <br/>tu contraseña?</h3>
            <div className="form_box">

              <div className="form_box_item">
                <label>Nuevo Password</label><br/>
                <input
                  type="password"
                  id='mail'
                  name='mail'
                  onChange={this.handlerPass}
                  className="active"
                /><br/>
                <small id="mensajeMail"></small>
              </div>

            <button  type="button" onClick={this.onSubmit} className="btn btn_cta">Enviar</button><br/>
            <small id="mensajeBtn"></small>

            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ResetPassword;
