import React, { useState, useEffect } from "react";

import Lottie from 'lottie-react';
import bye from '../img/bye.json';






class Logout extends React.Component{

  render(){

    return(
      <>
        <Lottie
          animationData={bye}
          loop={true}
          className="logout_bye"
        />
        <div className="logout_bye_msj">Nos vemos pronto!</div>
      </>
    );
  }
}

export default Logout;
