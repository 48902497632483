import React, { useState, useEffect } from 'react';

const Picture = (props) => {
    const [image, setImage] = useState("");
    const defaultImage = require('../img/placeholder.png');

    useEffect(() => {

        if(props.images) {
            let images = props.images;

            if(images.length > 1) {
                let imagesArr = images.split(',');
                setImage(imagesArr[0]);
            }
            else {
                setImage(images);
            }
        }

        else if(props.videos) {

            var videos = props.videos;
            let a,b = "";

            var videosArr = videos.split(',');
            a = videosArr[0].split("v=")[1];

            if(a === undefined || a === "") {
                setImage(defaultImage);
            }

            else {
                b = a.split('&')[0];
                setImage(`https://img.youtube.com/vi/${b}/hqdefault.jpg`);
            }
        }

        else {
            setImage(defaultImage);
        }

    }, []);

    return <img src={image} />
}

export default Picture;
