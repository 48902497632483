import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FrigorificoGancho from '../components/FrigorificoGancho';
import FrigorificoEstancia from '../components/FrigorificoEstancia';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#19513c'
		},
		secondary: {
			main: '#ff9900'
		},
	},
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Frigorifico = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banners/frigorificos.png') } />
			<div className="properties-container">

				<p className="section-description">
					Brindamos el servicio de colocación de tus animales, a frigorífico llevando a cabo el
					control de faena, romaneo y otorgando al cliente la garantía de El Rodeo S.A. de que
					los quilos al gancho son certeros. La empresa defiende con seriedad y responsabilidad
					el negocio de los productores ganaderos en todo el país. El Rodeo S.A. es quien da
					el respaldo y la garantía del pago a los vendedores.
				</p>

				<ThemeProvider theme={theme}>
				<Box sx={{ width: '100%' }}>
					<Box>
						<Tabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
							<Tab label="AL GANCHO" {...a11yProps(0)} />
							<Tab label="EN ESTANCIA" {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<FrigorificoGancho />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<FrigorificoEstancia />
					</TabPanel>
				</Box>
				</ThemeProvider>
			</div>
		</div>
	)
}

export default Frigorifico;
