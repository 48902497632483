import React from 'react';
import logoFooter from '../img/logo_footer.svg';
import facebook from '../img/facebook.png';
import youtube from '../img/youtube.png';
import instagram from '../img/instagram.png';
import twitter from '../img/twitter.svg';
import creadores from '../img/logo-creadores.png';
import phoneCall from '../img/phone-call.png';
import email from '../img/email.png';
import map from '../img/map.png';


const Footer = () => {

	return (
		<div>
			<div className="footer">

				<div>
					<h4 className="footer_title">Correo:</h4>
					<img className="footer_icon" src={email}></img>
					<span className="footer_text">remates@remates.com</span>
					<br />
					<br />
					<h4 className="footer_title">Teléfono:</h4>
					<img className="footer_icon" src={phoneCall}></img>
					<span className="footer_text">+595 981 999888</span>
				</div>


				<div className="footer-info-wrapper">
					<h4 className="footer_title">Oficina Comercial</h4>
					<img className="footer_icon" src={map}></img>
					<span className="footer_text"><a href="#">Mayor Bullo esq. Gral Genes - Lambaré</a></span>
					<br />
					<br />
					<h4 className="footer_title">Oficina Administrativa</h4>
					<img className="footer_icon" src={map}></img>
					<span className="footer_text"><a href="#">Mayor Bullo esq. Gral Genes - Lambaré</a></span>
				</div>

				<div className="footer_logo">
					<a href="#" target="_blank">
						<img
							src={facebook}
							alt="facebook"
							className="navbar_rrss-icon-png"
						/>
					</a>
					<a href="#" target="_blank">
						<img
							src={instagram}
							alt="instagram"
							className="navbar_rrss-icon-png"
						/>
					</a>
					<a href="#" target="_blank">
						<img
							src={youtube}
							alt="youtube"
							className="navbar_rrss-icon-png"
						/>
					</a>
				</div>
			</div>

			<div className="sub-footer">
				<p>Desarrollado por: </p><a href="https://creadores.com.py/" target="_blank"><img src={creadores} /></a>
			</div>

		</div>
	)
}

export default Footer;
