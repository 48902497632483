import React, {useState, useEffect} from 'react';
import LotesInvernada from '../pages/Invernada';
import LotesInvernadaMobile from '../pages/InvernadaMobile';
import configuration from '../config/configuration.json';
import {Link} from 'react-router-dom';
import Filter from '../components/filter';
import Lottie from 'lottie-react';
import BannerImage from '../components/BannerImage';

//Table
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
//import Paper from '@mui/material/Paper';
import ovni from '../json/ovni.json';
import Cookies from 'universal-cookie';
import Preloader from '../components/Preloader';
import { useMediaQuery } from 'react-responsive';

const cookies = new Cookies();

const InvernadaPage = () => {

    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    const isFromTablet = useMediaQuery({ minWidth: '46.25em' });
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [data, setData] = useState([]);
    const [on, setOn] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [fur, setFur] = useState([]);
    const [category, setCategory] = useState([]);
    const [race, setRace] = useState([]);
    const [notFound, setNotFound] = useState(false);

    const bannerImages = [
        //require('../img/banner_invernada.jpg'),
    ]

    const catalog = ""; //require('../img/Catalogo_Feria_Cooperados.pdf');

    function filtering(data) {

        var categoryList = data.filter(data => data.lote.category !== null).sort((a,b) => a.lote.category.localeCompare(b.lote.category)).map((lote)=>{
            return lote.lote.category
        })

        var categoriaFiltrada = categoryList.filter((item, index)=>{
            return categoryList.indexOf(item) === index;
        });

        var categoriaFiltradaSinNulos = categoriaFiltrada.filter(item => {return item !== null});

        setCategory(categoriaFiltradaSinNulos);

        let furList = data.filter(data => data.lote.fur !== null).sort((a,b) => a.lote.fur.localeCompare(b.lote.fur)).map((lote) => {
            return lote.lote.fur;
        });

        let furFiltrada = furList.filter((item, index) => {
            return furList.indexOf(item) === index;
        })

        var furFiltradaSinNulos = furFiltrada.filter(item => {return item !== null});
        setFur(furFiltradaSinNulos);

        var raceList = data.filter(data => data.lote.race !== null).sort((a,b) => a.lote.race.localeCompare(b.lote.race)).map((lote)=>{
            return lote.lote.race
        });

        var raceFiltrada = raceList.filter((item, index)=>{
            return raceList.indexOf(item) === index;
        });

        var raceFiltradaSinNulos = raceFiltrada.filter(item => {return item !== null});
        setRace(raceFiltradaSinNulos);
    }

    useEffect(() => {
        setLoading(true);

        fetch(process.env.REACT_APP_URL_API + configuration.GET_INVERNADA_LOTES,{
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify({ userMail: cookies.get('mail') })
        })
            .then((response)=> response.json())
            .then((data)=> {
                console.log(data);

                if(data.data.loteList === null) {
                    setNotFound(true);
                }

                setData(data.data.loteList) 
            })
            .catch((error) => { console.log(error); setNotFound(true); })
            .finally(() => setLoading(false));

    }, [])

    // Renderiza el filtro
    useEffect(() => {
        if(data) {
            filtering(data);
        }
    }, [data]);

    const getFilter = (fur, race, category, minvalue, maxvalue) => {
        setFilterLoading(true);

        let a = data;

        if(fur) {
            a = a
                .filter(lote => {
                return lote.lote.fur == fur;
            })
        }

        if(race) {
            a = a
                .filter(lote => {
                return lote.lote.race == race;
            })
        }

        if(category) {
            a = a
                .filter(lote => {
                return lote.lote.category == category;
            })
        }

        if(minvalue) {
            a = a
                .filter(lote => {
                return lote.offer > minvalue;
            })
        }

        if(maxvalue) {
            a = a
                .filter(lote => {
                return lote.offer <= maxvalue;
            })
        }

        setFilterData(a);
        setOn(true);
    }

    useEffect(() => {
        setFilterLoading(false);
    }, [filterData]);

    return (
        <>
            { notFound ?
                <div className="lottie-loading">
                    <Lottie
                        animationData={ovni}
                        loop={true}
                        className="lottie-loading-anim"
                    />
                    <h4 className="textoConsola">No se ha encontrado ningún lote activo.</h4>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            {/* bannerImages.length > 0 && (
                                <div className="lotesBig_portada banner-invernada">
                                    <BannerImage images={ bannerImages } />
                                </div>
                            )*/}

                            {/* catalog && (
                                <div className="text-center mt-3">
                                    <a className="btn-ppl btn-pdf" href={catalog} target="_blank">
                                        DESCARGAR CATÁLOGO
                                    </a>
                                </div>
                            )*/}


                            <div className="invernada">
                                <div className="invernada_layout">

                                    <div className="breadcrum">
                                        <u><Link to="/">Inicio</Link></u> {" > "}
                                        Invernada
                                    </div>

                                    <Filter
                                        maxPrice={maxPrice}
                                        minPrice={minPrice}
                                        race={race}
                                        fur={fur}
                                        category={category}
                                        filter={getFilter}
                                    />

                                    {/*
                    <div className="button-list-view-wrapper">
                        <button onClick={ () => this.ToggleGrid() } className={ this.state.toggleGrid === true ? 'buttonView buttonGrid viewActive' : 'buttonView buttonGrid' }>
                            <ViewComfyIcon sx={{ fontSize: 20 }} />
                        </button>
                        <button onClick={ () => this.ToggleList() } className={ this.state.toggleGrid !== true ? 'buttonView buttonList viewActive' : 'buttonView buttonList' }>
                            <ViewHeadlineIcon sx={{ fontSize: 20 }} />
                        </button>
                    </div>
                                */}

                                    {!filterLoading ?
                                        <>
                                            { on
                                                ?
                                                <div className={ bannerImages.length > 0 && catalog !== "" ? "lotes-container" : "lotes-invernada-container" }>
                                                    {
                                                        filterData.length < 1 ?
                                                            <>
                                                                <Lottie
                                                                    animationData={ovni}
                                                                    loop={true}
                                                                    className="lottie-loading-anim"
                                                                />
                                                                <h4 className="textoConsola">No se ha encontrado ningún lote con esos filtros.</h4>
                                                            </>
                                                            :
                                                            <>
                                                                { isMobileToTablet && (
                                                                    filterData.sort((a,b) => a.lote.order - b.lote.order).map((lote, i) => (
                                                                        <LotesInvernadaMobile key={i} data={lote} />
                                                                    ))
                                                                )}

                                                                { isFromTablet && (
                                                                    filterData.sort((a,b) => a.lote.order - b.lote.order).map((lote, i) => (
                                                                        <LotesInvernada key={i} data={lote} />
                                                                    ))
                                                                )}
                                                            </>
                                                    }

                                                </div>
                                                :
                                                <div className="lote-container">

                                                    { isMobileToTablet && (
                                                        filterData.sort((a,b) => a.lote.order - b.lote.order).map((lote, i) => (
                                                            <LotesInvernadaMobile key={i} data={lote} id="b" />
                                                        ))
                                                    )}

                                                    { isFromTablet && (
                                                        filterData.sort((a,b) => a.lote.order - b.lote.order).map((lote, i) => (
                                                            <LotesInvernada key={i} data={lote} id="b" />
                                                        ))
                                                    )}
                                                </div>
                                            }
                                        </>
                                        :
                                        <div className="lottie-loading">
                                            <Preloader />
                                        </div>
                                    }

                                </div>
                            </div>
                        </>
                        :
                        <div className="lottie-loading">
                            <Preloader />
                        </div>
                    }
                </>
            }
        </>
    )
}

export default InvernadaPage;
