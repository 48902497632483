import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import { useMediaQuery } from 'react-responsive';
import ConsumoTable from '../components/ConsumoTable';
import ConsumoMobile from '../components/ConsumoMobile';
import axios from 'axios';
import Preloader from '../components/Preloader';

const Consumo = () => {
	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '46.25em' });

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		let ignore = false;

		async function fetchData() {
			await axios
			.get(process.env.REACT_APP_URL_API + configuration.GET_LAST_FERIA)
			.then((response) => {
				if (!ignore) {
					setData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setIsLoading(false);
			})
		}

		fetchData();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
			<div className="consumoContainer">
				<div className="consumoContent">
					<ConsumoTopTitle />
					<ConsumoTopButtons />

					{ !isLoading ?
						<>
							<div className="consumoDataContent">
								<h2>INFORME DE FERIA</h2>
							</div>

							{ data.locationResume && (
								<div className="contentDataTableContainer">
									{ isMobileToTablet && ( <ConsumoMobile
										calculationType={ "cant" }
										dataTable={ data.locationResume } /> )}

									{ isFromTablet && ( <ConsumoTable
										calculationType={ "cant" }
										dataTable={ data.locationResume } /> )}
								</div>
							)}

							<div className="consumoDataContent">
								<h2>Resumen general EL RODEO S.A</h2>
								<h3 className="noTextDecoration">Cuadro de Precios</h3>
							</div>

							{ data.priceResume && (
								<div className="contentDataTableContainer mb-3">
									{ isMobileToTablet && ( <ConsumoMobile
										calculationType={ "cant" }
										dataTable={ data.priceResume } /> )}

									{ isFromTablet && ( <ConsumoTable
										calculationType={ "cant" }
										dataTable={ data.priceResume } /> )}
								</div>
							)}
						</>
						:
						<div className="lottie-loading">
							<Preloader />
					</div>
					}
				</div>
			</div>
		</>
	)
}

export default Consumo;
