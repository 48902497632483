import React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from './components/scrollToTop'
import Layout from './layout/layout';
import Header from './components/header2';
import RematesPage from './pages/rematesPage';
import Logout from './pages/logout';
import Lotes from "./pages/Lotes";
import FichaPage from './pages/fichaPage';
import Login from './pages/login';
import Register from './pages/register';
import Error from './pages/404';
import Migrar from './pages/migrar';
import PasswordChange from './pages/passwordChange';
import Puja from './pages/Puja';
import Proximamente from './pages/proximamente';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/reset';
import UserProfile from './pages/userProfile';
import UserProfileEdit from './pages/userProfile-edit';
import Cookies from 'universal-cookie';
import InvernadaPage from './pages/invernadaPage';
import InvernadaFicha from './pages/invernadaFicha';
import PujaInvernada from './pages/PujaInvernada';
import Consumo from "./pages/Consumo";
import ConsumoPreciosAnteriores from "./pages/ConsumoPreciosAnteriores";
import ConsumoPrecioYear from "./pages/ConsumoPrecioYear";
import ConsumoEstadisticas from "./pages/ConsumoEstadisticas";
import ConsumoEstadisticasMonths from "./pages/ConsumoEstadisticasMonths";
import ConsumoLiquidaciones from './pages/ConsumoLiquidaciones';
import ConsumoLiquidacionesInformes from './pages/ConsumoLiquidacionesInformes';
import ConsumoLiquidacionesInforme from "./pages/ConsumoLiquidacionesInforme";
import OffersHistory from "./pages/OffersHistory";
import OfferHistory from "./pages/OfferHistory";
import Newsletter from "./pages/Newsletter";
import WorkWithUs from "./pages/WorkWithUs";
import Terms from "./pages/Terms";
import RuralProperties from "./pages/RuralProperties";
import Frigorifico from "./pages/Frigorifico";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Faq from './pages/Faq';
import HabilitarCarpeta from "./pages/HabilitarCarpeta";
import AboutUs from "./pages/AboutUs";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "p",
            logged: false
        }
    }

    handleUser = (e, c, t, m, l) => {

        const cookies = new Cookies();
        cookies.set('user', e, { path: '/' });
        cookies.set('lastName', l, { path: '/' });
        cookies.set('token', t, { path: '/' });
        cookies.set('mail', m, { path: '/' });

        this.setState(this.state = {
            ...this.state,
            logged: c,
            userName: e,
            token: t,
            userMail: m,
            lastName: l
        }

        );
    }

    handleLogout = (url) => {
        const cookies = new Cookies();

        cookies.remove('user', { path: '/' });
        cookies.remove('token', { path: '/' });
        cookies.remove('mail', { path: '/' });
        cookies.remove('lastName', { path: '/' });

        this.setState(this.state = {
            ...this.state,
            logged: false,
            userName: " ",
            lastName: " "
        }
        );

        if (url !== undefined) {
            window.location.href = "/login?u=" + url;
        }
        else {
            window.location.href = "/login";
        }
    }

    componentDidMount() {
        const cookies = new Cookies();

        let usuarioCookie = cookies.get('user');
        let usuarioToken = cookies.get('token');
        let mailToken = cookies.get('mail');
        let lastName = cookies.get('lastName');

        if (usuarioCookie) {
            this.setState({ userName: usuarioCookie });
            this.setState({ logged: true })
        }
        if (usuarioToken) {
            this.setState({ token: usuarioToken });
        } else {
            this.setState({ logged: false })
        }
        if (mailToken) {
            this.setState({ userMail: mailToken });
        } else {
            this.setState({ logged: false })
        }
        if (lastName) {
            this.setState({ lastName: lastName });
        } else {
            this.setState({ logged: false })
        }

    }

    render() {
        return (
            <BrowserRouter>
                <Layout data={this.state} handleLogout={this.handleLogout}>
                    <Header handleLogout={this.handleLogout} logged={this.state.logged} />
                    <ScrollToTop />
                    <Routes>
                        {/*
                        <Route exact path="/" element={<Home />}/>
                        */}
                        <Route exact path="/" element={<RematesPage />} />
                        <Route exact path="/contactanos" element={<ContactUs />} />
                        <Route exact path="/preguntas-frecuentes" element={<Faq />} />
                        <Route exact path='/logout' element={<Logout />} />
                        <Route exact path='/lote/:id' element={<Lotes userMail={this.state.userMail} />} />
                        <Route exact path='/remate/:remateId/lote/:loteId' element={<FichaPage />} />
                        <Route exact path='/login' element={<Login handleUser={this.handleUser} />} />
                        <Route exact path='/register' element={<Register />} />
                        <Route exact path='/migrar' element={<Migrar />} />
                        <Route exact path='/cambiar-contrasena' element={<PasswordChange token={this.state.token} />} />
                        <Route exact path='/puja/:loteId/:base' element={<Puja token={this.state.token} userMail={this.state.userMail} />} />
                        <Route exact path='/comingsoon' element={<Proximamente />} />
                        <Route exact path='/recuperacion-contrasena' element={<ForgotPassword />} />
                        <Route exact path='/auth/reset-password/:token' element={<ResetPassword />} />
                        <Route exact path='/profile' element={<UserProfile token={this.state.token} />} />
                        <Route exact path='/profile/edit' element={<UserProfileEdit token={this.state.token} handleUser={this.handleUser} />} />
                        <Route exact path='/invernada' element={<InvernadaPage userMail={this.state.userMail} />} />
                        <Route exact path='/consumo' element={<Consumo userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/precios-anteriores' element={<ConsumoPreciosAnteriores userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/precios-anteriores/:year' element={<ConsumoPrecioYear userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/estadisticas' element={<ConsumoEstadisticas userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/estadisticas/:year/m/:month' element={<ConsumoEstadisticasMonths userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/liquidaciones' element={<ConsumoLiquidaciones userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/liquidaciones/:user/informes' element={<ConsumoLiquidacionesInformes userMail={this.state.userMail} />} />
                        <Route exact path='/consumo/liquidaciones/:user/informes/:informe/f/:fecha' element={<ConsumoLiquidacionesInforme userMail={this.state.userMail} />} />
                        <Route exact path='/invernada/:id' element={<InvernadaFicha userMail={this.state.userMail} />} />
                        <Route exact path='/invernada/ofertar/:id' element={<PujaInvernada userMail={this.state.userMail} token={this.state.token} />} />
                        <Route exact path="/historial-ofertas" element={<OffersHistory userMail={this.state.userMail} token={this.state.token} />} />
                        <Route exact path="/historial-oferta/:id/:lote" element={<OfferHistory userMail={this.state.userMail} token={this.state.token} />} />
                        <Route exact path="/suscribir-boletin" element={<Newsletter />} />
                        <Route exact path="/trabaja-con-nosotros" element={<WorkWithUs />} />
                        <Route exact path="/terminos-y-condiciones" element={<Terms />} />
                        <Route exact path="/frigorificos" element={<Frigorifico />} />
                        <Route exact path="/propiedades-rurales" element={<RuralProperties />} />
                        <Route exact path="/habilitar-carpeta" element={<HabilitarCarpeta />} />
                        <Route exact path="/quienes-somos" element={<AboutUs />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        )
    }
}

export default App;
