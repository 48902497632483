import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import ConsumoTopTitle from '../components/ConsumoTopTitle';
import ConsumoTopButtons from '../components/ConsumoTopButtons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

const ConsumoLiquidaciones = () => {
	const [inputValue, setInputValue] = useState('');
	const [data, setData] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		let ignore = false;

		axios.get(process.env.REACT_APP_URL_API + configuration.GET_SELLER_DATES + "/" + inputValue)
		.then((response) => {
				if (!ignore) {
					if(response.data.status === "Error") {
						setOpenDialog(true);
					}
					else {
						setData(response.data.data);
					}
				}
		})
		.catch((error) => {
			console.log(error);
		})
		.then(() => {
			ignore = true;
		})
	}

	useEffect(() => {
		if(data !== null) {
			if(data.dates.length > 0) {
				window.location.href = `/consumo/liquidaciones/${inputValue}/informes`;
			}
			else {
				setOpenDialog(true);
			}
		}
	}, [data]);

	const handleValue = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
       setInputValue(event.target.value);
    }
	}

	const closeWindow = () => {
		setOpenDialog(false);
	}

	return (
		<div className="consumoContainer">
			<div className="consumoContent">
				<ConsumoTopTitle />
				<ConsumoTopButtons active={3} />

				<div className="consumoDataContent">
					<h2 className="mobileSpacing">INGRESE SU PIN:</h2>
				</div>

				<form
					className="loginLiquidacionesWrapper"
					onSubmit={(e) => { handleSubmit(e) }}>
					<input type="text" maxLength="9" className="inputLoginLiquidaciones" value={ inputValue } onChange={ handleValue } />
					<button type="submit" className="buttonLoginLiquidaciones">Ingresar</button>
				</form>
			</div>

			<Dialog
				open={ openDialog }
				onClose={ closeWindow }
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>

				<DialogTitle className="dialogError" id="alert-dialog-title">
					{ "Error de validación" }
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<b>El PIN ingresado no es correcto, por favor, ingrese un PIN válido.</b>
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialogActionWrapper">
					<Button className="dialogBtn closeDialogError" onClick={closeWindow}>Cerrar</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ConsumoLiquidaciones;
