import React from 'react'
import { Link } from 'react-router-dom'

const ConsumoTopTitle = () => {
	return (
		<div className="consumoTitleContent">
			<h1 className="consumoTitle">FERIAS DE CONSUMO</h1>
			{ false && (
				<Link to="#" className="consumoTitleLink">Histórico de precios</Link>
			)}
		</div>
	)
}

export default ConsumoTopTitle
