import React, { useState, useEffect } from "react";
import { Navigate, Link} from 'react-router-dom';
import configuration from '../config/configuration.json';
import NumberFormat from 'react-number-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';
import LoteTimerOnly from "../components/LoteTimerOnly";
import {Helmet} from "react-helmet";
import ShareButtons from "../components/ShareButtons";
import StarIcon from '@mui/icons-material/Star';
import { useMediaQuery } from 'react-responsive';
import FotosGaleria from '../components/fotosGaleria';
import VideosComponent from '../components/videos-component';
import ReactLightBox from '../components/reactLightbox';
import Preloader from '../components/Preloader';
import Picture from '../components/picture';

import {
    Bar,
    Label,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ComposedChart,
    ResponsiveContainer
} from 'recharts';

const cookies = new Cookies();

const PujaInvernada = (props) => {
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });

    const [chartData, setChartData] = useState(null);
    const [lote, setLote] = useState([]);
    const [loading, setLoading] = useState(false);
    //const remateId = window.location.href.split('/')[4];
    const loteId = window.location.href.split('/')[5];
    const [status, setStatus] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogBuyout, setOpenDialogBuyout] = useState(false);
    const [newOffer, setNewOffer] = useState(0);
    const [currentOffer, setCurrentOffer] = useState(0);
    const userMail = cookies.get('mail');
    const [alias, setAlias] = useState('');
    const [error404, setError404] = useState(false);
    const [buyoutPrice, setBuyoutPrice] = useState(0);
    const [btnOffer, setBtnOffer] = useState(false);
    const [btnOfferBuyout, setBtnOfferBuyout] = useState(false);
    const [isYourOffer, setIsYourOffer] = useState(false);
    const [initialStatus, setInitialStatus] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [loteBackground, setLoteBackground] = useState('');
    const [offer, setOffer] = useState(0);
    const [minOfferValue, setMinOfferValue] = useState(0);

    const closeDialogWindowBuyout = () => {
        setOpenDialogBuyout(false);
    }

    const decreaseOfferAmount = () => {
        if(newOffer > currentOffer) {
            setNewOffer(parseInt(newOffer) - parseInt(lote.lote.minOfferIncrement))
        }
    }

    const increaseOfferAmount = () => {
        setNewOffer(parseInt(newOffer) + parseInt(lote.lote.minOfferIncrement))
    }

    const closeDialogWindow = () => {
        setOpenDialog(false);
    }

    const setSnackbarMsj = (msj, error) => {

        var snackbarMsj = document.getElementById('snackbar_msj');
        var snackbarBox = document.getElementById('snackbar_box');

        snackbarMsj.innerText = msj
        snackbarBox.classList.add("snackbar-hd");

        if(error) {
            snackbarBox.classList.add("snackbar-error");
        }

        setTimeout(()=> {
            snackbarBox.classList.remove("snackbar-hd", "snackbar-error");
        },3000);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setBtnOffer(true);

        var snackbarMsj = document.getElementById('snackbar_msj');
        var snackbarBox = document.getElementById('snackbar_box');
        var date = new Date(lote.lote.end);

        if( (Math.floor(date / 1000) - Math.floor(Date.now() / 1000)) < 0  ){

            snackbarMsj.innerText = "El lote ha cerrado"
            snackbarBox.classList.add("snackbar-hd");
            snackbarBox.classList.add("snackbar-error");

            setTimeout(() => {
                snackbarBox.classList.remove("snackbar-hd");
                snackbarBox.classList.remove("snackbar-error");
            },3000);

            var msjErr = document.getElementById('mensajeErr');
            return null;
        }

        var a = parseInt(lote.lote.minOfferIncrement) + parseInt(offer);
        var msjErr = document.getElementById('mensajeErr');

        if( a > parseInt(newOffer)) {
            setBtnOffer(false);

            msjErr.innerText = "La oferta tiene que superior a: " + a;
            snackbarMsj.innerText = "La oferta tiene que superior a: " + a;
            snackbarBox.classList.add("snackbar-hd");
            snackbarBox.classList.add("snackbar-error");

            setTimeout(()=>{
                snackbarBox.classList.remove("snackbar-hd");
                snackbarBox.classList.remove("snackbar-error");
            },3000);

            return null;
        }

        if(props.token) {
            fetch(process.env.REACT_APP_URL_API + configuration.INVERNADA_BID_UP, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "accept": "text/plain",
                    'Authorization': 'Bearer ' + props.token
                },
                body: JSON.stringify({
                    offer: newOffer,
                    loteId: loteId
                }),
            })
                .then((response)=> response.json())
                .then((data)=> {
                    setBtnOffer(false);
                    var msjOk = document.getElementById('mensajeOk');

                    if(data.status === 'Ok') {
                        snackbarMsj.innerText = "Puja Correcta"
                        snackbarBox.classList.add("snackbar-hd");

                        setTimeout(()=>{
                            snackbarBox.classList.remove("snackbar-hd");
                        },3000)

                    }else{
                        console.error(data.data);
                    }

                    fetchLotes();
                })
                .catch((error)=> { console.log(error); })
                .finally(()=> { setLoading(false); });

        }else{

            var msjErr = document.getElementById('mensajeErr');

            snackbarMsj.innerText = "Logeese antes de ofertar";
            snackbarBox.classList.add("snackbar-hd");
            snackbarBox.classList.add("snackbar-error");
            setTimeout(() => {
                snackbarBox.classList.remove("snackbar-hd");
                snackbarBox.classList.remove("snackbar-error");
            },3000);
        }
    }
    const performBuyout = (e) => {
        e.preventDefault();
        setBtnOfferBuyout(true);

        if(buyoutPrice > 0) {
            if(buyoutPrice < parseInt(newOffer) && buyoutPrice < parseInt(offer)) {
                setSnackbarMsj("El precio de compra directa no puede ser menor a la oferta actual", true)
                return null
            }
        }

        var date = new Date(lote.lote.end);

        if( (Math.floor(date / 1000) - Math.floor(Date.now() / 1000)) < 0  ){
            setSnackbarMsj("El lote ha cerrado", true);
            return null;
        }

        var a = parseInt(lote.lote.minOfferIncrement) + parseInt(offer)
        if( a  > parseInt(newOffer)){
            setBtnOfferBuyout(false);
            setSnackbarMsj("La oferta tiene que superior a: " + a, true);

            return null;
        }

        if(cookies.get('token')){
            fetch(process.env.REACT_APP_URL_API + configuration.INSTANT_BUYOUT_INVERNADA, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "accept": "text/plain",
                    'Authorization': 'Bearer ' + cookies.get('token')
                },
                body: JSON.stringify({
                    offer: buyoutPrice,
                    loteId: loteId
                }),
            })

                .then((response)=> response.json())
                .then((data)=> {
                    setBtnOfferBuyout(false);
                    if(data.status === 'Ok'){
                        setSnackbarMsj("Compra inmediata Correcta", false);
                    }

                    else {
                        setSnackbarMsj(data.data, true);
                    }

                    fetchLotes();
                })
                .catch((error)=> { console.log(error); })
                .finally(()=> {
                    setLoading(false);
                });
        }

        else {
            setSnackbarMsj("Logeese antes de ofertar", true);
            setBtnOfferBuyout(false);
        }
    }

    const fetchLotes = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_URL_API + configuration.GET_INVERNADA_LOTES,{
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
            mode: 'cors',
            cache: 'default',
            body: JSON.stringify(
                {
                    userMail: userMail
                }),
        })
            .then((response) => response.json())
            .then((data) => {

                var loteFiltrado = data.data.loteList.filter(lote => {
                    return lote.lote.id == loteId
                })

                if(loteFiltrado.length <= 0) {
                    setError404(true);
                }

                else {

                    //Initial lote states.
                    let dateNow = new Date();
                    let dateStart = new Date(loteFiltrado[0].lote.start);
                    let dateEnd = new Date(loteFiltrado[0].lote.end);

                    if(dateNow < dateStart) {
                        setLoteBackground('inactive-lote');
                    }
                    else if(dateNow > dateStart && dateNow < dateEnd) {
                        setLoteBackground('active-lote');
                    }
                    else if(dateNow > dateEnd) {
                        setLoteBackground('finished-lote');
                    }

                    setLote(loteFiltrado[0]);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchLotes();
    }, []);

    useEffect(() => {
        if(lote.lote) {
            setOffer(lote.offer);
            setAlias(lote.userAlias);
            setBuyoutPrice(lote.lote.buyoutPrice);
            setMinOfferValue(lote.lote.minOfferValue);
            setIsYourOffer(lote.isYourOffer);
            setNewOffer(parseInt(lote.lote.minOfferIncrement) + parseInt(lote.offer));
            setCurrentOffer(parseInt(lote.lote.minOfferIncrement) + parseInt(lote.offer));

            let chartArray = [];

            if(lote.lote.weightDistributions.length > 0) {
                lote.lote.weightDistributions.map(dist => {
                    let dataName = `${dist.minRange} - ${dist.maxRange}`;
                    chartArray.push({ name: dataName, qty: dist.quantity });
                });
            }

            chartArray.sort((a,b) => {
                return a.name.localeCompare(b.name);
            })

            setChartData(chartArray);
        }
    }, [lote]);

    function updateStatus(status, initial) {
        setStatus(status);
        setInitialStatus(initial);
    }

    useEffect(() => {
        if(initialStatus) {
            setLoteBackground('inactive-lote');
        }
        else if(!initialStatus && status) {
            setLoteBackground('active-lote');
        }
        else if(!initialStatus && !status) {
            setLoteBackground('finished-lote');
        }
    }, [status, initialStatus]);

    const focus = (e) => {
        e.target.select();
    }

    if(error404){
        return <Navigate to="/error"/>
    }

    return (
        <>
            { !loading ?
                <div>
                    { lote.lote && (
                        <>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Invernada Ofertar - { lote.lote.name } - El Rodeo Consignataria</title>
                                <meta
                                    property="og:title"
                                    content={ `Invernada Ofertar - ${ lote.lote.name }` } />
                                <link rel="canonical" href={ window.location.href } />
                                <meta
                                    property="og:url"
                                    content={ window.location.href } />
                            </Helmet>

                            <div className="breadcrum">
                                <u><Link to="/">Inicio</Link></u> {" > "}
                                <u><Link to="/invernada">Invernada</Link></u> {" > "}
                                Pre-ofertar
                            </div>

                            <div className="form-m" >
                                <div className="ujaForm">
                                    <div className="pujaForm_container">
                                        <div className={`pujaForm_name ${loteBackground}`}>
                                            {lote.lote.name}
                                        </div>

                                        <div className="pujaForm_infobox">
                                            <div className="pujaForm_infobox_img inner-box-image-wrapper">
                                                <Picture
                                                    images={ lote.lote.images }
                                                    videos={ lote.lote.videos } />
                                            </div>

                                            <div className="pujaForm_infobox_text">
                                                <h3>{lote.lote.owner}</h3>

                                                <LoteTimerOnly
                                                    updateStatus={ updateStatus }
                                                    start={ lote.lote.start }
                                                    end={ lote.lote.end } />

                                                <div className="puja-detail-wrapper">
                                                    <p><b>Localidad:</b> {lote.lote.location}</p>
                                                    <p><b>Establecimiento:</b> {lote.lote.stablishment}</p>
                                                </div>

                                                { isYourOffer ?
                                                    <div className="lote-your-offer">
                                                        <StarIcon />
                                                        Tu Pre-oferta
                                                    </div>
                                                    :
                                                    minOfferValue > 1 && (
                                                        <div className="property-block mt-1">
                                                            <p className="green-text">{ alias ? `Ofertante: ${alias}` : "No hay ofertas" }</p>
                                                        </div>
                                                    )
                                                }

                                                <p className="p-share-text">Compartir en</p>
                                                <ShareButtons
                                                    url={ window.location.href }
                                                    title={ `Invernada Ofertar - ${lote.lote.name}` } />
                                            </div>
                                        </div>

                                        { status && (
                                            <div className="pujaForm_secondBox">

                                                <div>
                                                    { minOfferValue > 1 &&
                                                        <div className="pujaForm_input">
                                                            <button className="buttonIncrease button-minus" onClick={ decreaseOfferAmount }>-</button>

                                                            <NumberFormat
                                                                displayType="text"
                                                                className="offerNumberValue"
                                                                thousandSeparator={'.'}
                                                                allowNegative={ false }
                                                                decimalSeparator={','}
                                                                onFocus={focus}
                                                                prefix={'Gs. '}
                                                                value={ newOffer }
                                                                onValueChange={(a)=> {
                                                                    setNewOffer(a.value)
                                                                }}
                                                            />

                                                            <button className="buttonIncrease button-plus" onClick={ increaseOfferAmount }>+</button>
                                                        </div>
                                                    }

                                                    <div className="pujaForm_botonera">

                                                        { minOfferValue > 1 &&
                                                            <button
                                                                type="button"
                                                                className={!btnOffer ? "pujaForm_botonera-ofertar" : "pujaForm_botonera-ofertar btn-disabled"}
                                                                onClick={onSubmit}
                                                                disabled={btnOffer}
                                                            >
                                                                {!btnOffer
                                                                    ?
                                                                    "Pre-ofertar"
                                                                    :
                                                                    <Preloader />
                                                                }
                                                            </button>
                                                        }

                                                        { buyoutPrice > 0 &&
                                                            <button
                                                                type="button"
                                                                className={!btnOffer ? "pujaForm_botonera-buyout" : "pujaForm_botonera-buyout btn-disabled"}
                                                                onClick={ performBuyout }
                                                                disabled={ btnOfferBuyout }
                                                            >
                                                                Compra Directa
                                                            </button>
                                                        }

                                                        { false &&
                                                            minOfferValue > 1 &&
                                                            <button
                                                                type="button"
                                                                className="pujaForm_botonera-update"
                                                                onClick={fetchLotes}
                                                            >
                                                                Actualizar última pre-oferta
                                                            </button>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="pujaForm_data">
                                                    { minOfferValue > 1 &&
                                                        <>
                                                            <div className="pujaForm_data_incremento">
                                                                <p>Incremento minimo</p>
                                                                <h5>{lote.lote.minOfferIncrement.toLocaleString('es-AR')} GS</h5>
                                                            </div>
                                                            <div className="pujaForm_data_oferta">
                                                                <p>Última pre-oferta</p>
                                                                <h5>{offer.toLocaleString('es-AR')} Gs</h5>
                                                            </div>
                                                        </>
                                                    }

                                                    { buyoutPrice > 0 &&
                                                        <div className="pujaForm_data_buyout">
                                                            <p>Compra Directa</p>
                                                            <h5>{buyoutPrice.toLocaleString('es-AR')} Gs</h5>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )}

                                        <div className="lote-information-container">
                                            <div className="ficha_datosPrincipales">
                                                <div className="ficha_datosSecundarios">
                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Categoría
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.category ? lote.lote.category : ""}
                                                        </p>
                                                    </div>

                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Raza
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.race ? lote.lote.race : ''}
                                                        </p>
                                                    </div>

                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Pelaje
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.fur ? lote.lote.fur : ''}
                                                        </p>
                                                    </div>

                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Carimbo
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.carimbo ? lote.lote.carimbo : ''}
                                                        </p>
                                                    </div>

                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Cantidad
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.quantity ? lote.lote.quantity : ''}
                                                        </p>
                                                    </div>

                                                    <div className="ficha_datosSecundarios_cajas">
                                                        <p className="ficha_datosSecundarios_cajas_titulo">
                                                            Peso Promedio
                                                        </p>
                                                        <p className="ficha_datosSecundarios_cajas_dato">
                                                            {lote.lote.averageWeight ? lote.lote.averageWeight : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ficha_stats">
                                                <div className="ficha_stats_caja">
                                                    <div className="ficha_stats_caja_img">
                                                        <img src="./img/observaciones-icon.svg" alt=""/>
                                                    </div>
                                                    <div className="ficha_stats_caja_titulo">
                                                        Observaciones
                                                    </div>
                                                    <div className="ficha_stats_caja_texto">
                                                        {lote.lote.longObs ? lote.lote.longObs : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="video">
                                                <VideosComponent videos={ lote.lote.videos }/>
                                            </div>

                                            { chartData && (
                                                chartData.length > 0 && (
                                                    <ResponsiveContainer
                                                        className="chart-container"
                                                        width={ isMobileToTablet ? "100%" : 500 }
                                                        height={350}>

                                                        <ComposedChart
                                                            width={500}
                                                            height={350}
                                                            data={ chartData }
                                                            margin={{
                                                                top: 5,
                                                                right: 30,
                                                                bottom: 30,
                                                                left: 20
                                                            }}
                                                        >

                                                            <CartesianGrid stroke="#f5f5f5" />
                                                            <XAxis dataKey="name">
                                                                <Label value="Rangos" offset={-10} position="insideBottom" fontSize="14" fill="#19513c" />
                                                            </XAxis>
                                                            <YAxis label={{ value: 'Cantidad', angle: -90, position: 'insideLeft', fontSize: 14, fill: '#19513c' }} />
                                                            <Tooltip />

                                                            <Bar dataKey="qty" name="Cantidad" fillOpacity={1} fill="#19513c" />
                                                        </ComposedChart>
                                                    </ResponsiveContainer>
                                                )
                                            )}

                                            <FotosGaleria img={lote.lote.images} toggle={setToggle}/>
                                            <ReactLightBox toggle={toggle} img={lote.lote.images}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Dialog
                                open={ openDialogBuyout }
                                onClose={ closeDialogWindowBuyout }
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >

                                <DialogTitle className="dialogTitle" id="alert-dialog-title">
                                    { "Confirmación de compra directa" }
                                </DialogTitle>

                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        ¿Estás seguro/a que deseas comprar el lote <b>{ lote.lote.name }</b> por el monto <b>Gs. { buyoutPrice.toLocaleString('es-AR') }</b>?
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions className="dialogActionWrapper">
                                    <Button className="dialogBtn cancelDialogButton" onClick={closeDialogWindowBuyout}>Cancelar</Button>
                                    <Button className="dialogBtn submitDialogButton" onClick={performBuyout} autoFocus>
                                        Comprar
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={openDialog}
                                onClose={closeDialogWindow}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >

                                <DialogTitle className="dialogTitle" id="alert-dialog-title">
                                    { "Confirmación de oferta" }
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        ¿Estás seguro/a que deseas ofertar por el lote <b>{ lote.lote.name }</b> por el monto <b>Gs. { newOffer.toLocaleString('es-AR') }</b>?
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions className="dialogActionWrapper">
                                    <Button className="dialogBtn cancelDialogButton" onClick={closeDialogWindow}>Cancelar</Button>
                                    <Button className="dialogBtn submitDialogButton" onClick={onSubmit} autoFocus>
                                        Ofertar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                </div>
                :
                <div className="lottie-loading">
                    <Preloader />
                </div>
            }
        </>
    );
}

export default PujaInvernada;
