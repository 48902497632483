import React from 'react';
import whatsapp from '../img/whatsapp.svg';


const Whatsapp = () => {
	return (
		<div className="whatsapp">
			<a href="#" target="_blank">
				<img
					src={whatsapp}
					alt="Whatsapp"
					className="navbar_rrss-icon"
				/>
			</a>
		</div>
	)
}

export default Whatsapp;
