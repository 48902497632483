import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import configuration from '../config/configuration.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

const theme = createTheme({
	palette: {
		primary: {
			main: '#19513c'
		},
		secondary: {
			main: '#ff9900'
		},
	},
});

const RuralProperties = () => {
	let captcha;

	const [name, setName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState("");
	const [location, setLocation] = useState(null);
	const [text, setText] = useState(null);

	const [captchaValidation, setCaptchaValidation] = useState(false);
	const [errors, setErrors] = useState("");
	const [open, setOpen] = useState(false);

	const [checking, setChecking] = useState(false);

	function closeDialogWindow() {
		setName(null);
		setLastName(null);
		setEmail(null);
		setPhone("");
		setLocation(null);
		setText(null);

		resetCaptcha();
		setOpen(false);
		setChecking(false);
	}

	const resetCaptcha = () => {
		captcha.reset();
	}

	function handlePhone(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setPhone(event.target.value);
		}
	}

	const handleSubmit = (e) => {
		const formData = new FormData();

		e.preventDefault();
		setChecking(true);
		setErrors([]);
		let ignore = false;
		let errorArray = [];

		if(!name) {
			errorArray.push("Campo nombre es requerido.");
			setChecking(false);
		}
		if(!lastName) {
			errorArray.push("Campo apellido es requerido.");
			setChecking(false);
		}

		if(!email) {
			errorArray.push("Campo correo electrónico es requerido.");
			setChecking(false);
		}

		if(!phone) {
			errorArray.push("Campo teléfono es requerido.");
			setChecking(false);
		}

		if(!location) {
			errorArray.push("Campo localidad es requerido.");
			setChecking(false);
		}

		if(!text) {
			errorArray.push("Campo comentario es requerido.");
			setChecking(false);
		}

		if(!captchaValidation) {
			errorArray.push("La validación del captcha es necesario.");
			setChecking(false);
		}

		if(errorArray.length > 0) {
			setErrors(errorArray);
		}

		else {
			let contentData = [];

			contentData = [
				{ "key": "Nombre", "value": name },
				{ "key": "Apellido", "value": lastName },
				{ "key": "Correo Electrónico", "value": email },
				{ "key": "Teléfono", "value": phone },
				{ "key": "Localidad", "value": location },
				{ "key": "Comentario", "value": text }
			];

			formData.append("subject", "Propiedades Rurales");
			formData.append("content", JSON.stringify(contentData));
			formData.append("isForEmail", false);
			formData.append("toEmail", "web-propiedades@elrodeo.com.py");
			formData.append("formType", "Propiedades_Rurales");
			formData.append("files", []);

			axios.post(configuration.PANEL_SERVER_URL + configuration.POST_SUBMIT_FORM, formData)
				.then((response) => {
					if (!ignore) {
						console.log(response.data);
						if(response.data.status == "error") {
							setChecking(false);
						}

						if(response.data.status == "Ok") {
							openDialog();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	function openDialog() {
		setOpen(true);
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
		}
		console.log("Captcha value:", value);
	}

	return (
		<>
			<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banners/propiedades_rurales.png') } />
				<div className="properties-container">
					{ false && (
						<p className="section-description">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					)}

					<h1 className="normal-top-title mb-1 pt-2">DEJANOS TUS DATOS</h1>
					<p className="align-center">Ingresá aquí tus datos para que podamos contactar contigo.</p>

					{ errors.length > 0 ?
						<>
							<p style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
							<strong>Por favor corrige los siguientes temas: </strong>
							</p>
							<ul
								style={{
									backgroundColor: "#ff000033",
									listStyle: 'none',
									padding: '17px',
									borderRadius: '8px',
									marginBottom: '1rem'
								}}
								>
								{ Object.values(errors).map(error => (
									<li>{ error }</li>
								))}
							</ul>
						</>
					: null
					}

					<ThemeProvider theme={theme}>
						<Box
							component="form"
							noValidate
							autoComplete="off"
							className="mt-2"
							onSubmit={ (e) => { handleSubmit(e) }}
							>
							<TextField
								className="input-newsletter"
								color={`primary`}
								fullWidth
								id="outlined-basic"
								required
								value={ name || "" }
								onChange={(event) => { setName(event.target.value) }}
								label="Nombre" variant="standard" />

							<TextField
								className="input-newsletter"
								required
								fullWidth
								id="outlined-basic"
								label="Apellido"
								value={ lastName || "" }
								onChange={(event) => { setLastName(event.target.value) }}
								variant="standard" />

							<TextField
								className="input-newsletter"
								type="mail"
								fullWidth
								required
								id="outlined-basic"
								label="Correo Electrónico"
								value={ email || "" }
								onChange={(event) => { setEmail(event.target.value) }}
								variant="standard" />

							<TextField
								className="input-newsletter"
								type="text"
								fullWidth
								required
								id="outlined-basic"
								label="Teléfono"
								value={phone}
								onChange={handlePhone}
								variant="standard" />

							<TextField
								className="input-newsletter"
								type="text"
								fullWidth
								required
								id="outlined-basic"
								label="Localidad"
								value={ location || "" }
								onChange={(event) => { setLocation(event.target.value) }}
								variant="standard" />

							<TextField
								className="input-newsletter"
								color={`primary`}
								multiline
								rows={5}
								style={{ marginTop: '1rem' }}
								fullWidth id="outlined-basic"
								value={ text || "" }
								onChange={(e) => { setText(e.target.value) }}
								label="Comentario *" variant="standard" />

							<div style={{ marginTop: '1.3rem' }}>
								<ReCAPTCHA
									ref={ (r) => setCaptchaRef(r) }
									sitekey="6LcmvCIkAAAAAAY9wLH-OCvLuZ8kIEl6PkcV4BaO"
									onChange={onChangeRecaptcha}
								/>
							</div>

							<div>
								<button
									className="button-submit"
									disabled={ checking ? true : false }
									type="submit">{ checking ? "Enviando..." : "Enviar mensaje" }</button>
							</div>
						</Box>
					</ThemeProvider>
				</div>
			</div>

			<Dialog
				open={open}
				onClose={closeDialogWindow}
				aria-labelledby="success-dialog-title"
				aria-describedby="success-dialog-description"
				>

				<DialogTitle className="dialogTitle" id="alert-dialog-title">
					{ "Confirmación de envío" }
				</DialogTitle>
				<DialogContent style={{ paddingBottom: 0 }}>
					<DialogContentText id="alert-dialog-description" className="alert-dialog-description">
						Muchas gracias por completar el formulario, nos pondremos en contacto contigo en la brevedad posible.
					</DialogContentText>
				</DialogContent>

				<DialogActions className="dialogActionWrapper">
					<Button className="dialogBtn submitDialogButton" onClick={closeDialogWindow} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default RuralProperties;
