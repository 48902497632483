import React from 'react';
import {NavLink} from 'react-router-dom';
import cookie from 'js-cookie';

const UserMenu = (props) => {

	const currentUrl = window.location.href;
	if(props.loggedIn) {
		return (

			<div className="navbar2_desplegable">
				<div className="navbar2_desplegable-punta"></div>
				<p>Bienvenido</p>
				<h2>{ cookie.get('user') } { cookie.get('lastName') }</h2>
				<hr/>
				<small>
					<NavLink to="/profile" onClick={props.userMenu}>
						Editar usuario
					</NavLink>
				</small>
				<small>
					<NavLink to="/historial-ofertas" onClick={props.userMenu}>
						Historial de ofertas
					</NavLink>
				</small>
				<small>
					<NavLink to="/cambiar-contrasena" onClick={props.userMenu}>
						Cambiar contraseña
					</NavLink>
				</small>
				<button className="btn btn_cta navbar2_userBtn" onClick={() => props.handleLogout(currentUrl) } >
					Cerrar sesión
				</button>
			</div>
		)
	}

	else {
		return (
			<div className="navbar2_desplegable">
				<div className="navbar2_desplegable-punta"></div>
				<div className="navbar2_desplegable_superior">
					<p>Bienvenido</p>
				</div>

				<NavLink to={`/login?u=${currentUrl}`} onClick={props.userMenu}>
					<div className="btn btn_cta navbar2_userBtn pastillaBtn" >
						Ingresar
					</div>
				</NavLink>

				<NavLink to="/register" onClick={props.userMenu}>
					<div className="btn navbar2_userBtn pastillaBtn">
						Registrarse
					</div>
				</NavLink>

			<hr/>
				<small>
					<u>
						<NavLink to="/recuperacion-contrasena" onClick={props.userMenu}>
							¿Olvidaste tu contraseña?
						</NavLink>
					</u>
				</small>
				<small>
					<u>
						<NavLink to="/migrar" onClick={props.userMenu}>
							Migrar usuario
						</NavLink>
					</u>
				</small>
			</div>
		)
	}
}

export default UserMenu;
