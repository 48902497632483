import React from 'react';

function AboutUs() {
	return (
		<div className="general-banner-container bg-white">
			<img className="inst-banner" src={ require('../img/banner_inst.png') } />
			<div className="middle-content-wrapper">
				<div className="inst-block-divider">
					<div className="ml-auto-block">
						<h2>¿Por qué lo hacemos?</h2>
						<p>Desde El Rodeo S.A. buscamos darle soluciones agiles e inteligentes a los clientes, favoreciendo siempre los buenos negocios y resultados económicos favorables para todas las partes. Ofrecemos una amplia variedad de servicios profesionales para el sector agropecuario, a partir de una demanda del mercado que nos elige para suplir sus necesidades.</p>
					</div>

					<div>
						<img src={ require('../img/inst_01.png') } />
					</div>
				</div>

				<h2 className="inst-titles">Nuestros Servicios</h2>
				<div className="inst-grid-elements">
					<div className="inst-element">
						<img src={ require('../img/inst_icon_01.png') } />
						<span>Ferias de Consumo</span>
					</div>
					<div className="inst-element">
						<img src={ require('../img/inst_icon_02.png') } />
						<span>Venta Directa</span>
					</div>
					<div className="inst-element">
						<img src={ require('../img/inst_icon_03.png') } />
						<span>Venta de Inmuebles Rurales</span>
					</div>
					<div className="inst-element">
						<img src={ require('../img/inst_icon_04.png') } />
						<span>Ferias de Invernada</span>
					</div>
					<div className="inst-element">
						<img src={ require('../img/inst_icon_05.png') } />
						<span>Ventas a Frigorifico</span>
					</div>
					<div className="inst-element">
						<img src={ require('../img/inst_icon_06.png') } />
						<span>Asesoramiento y trámites</span>
					</div>
				</div>

				<div className="inst-block-divider">
					<div>
						<img className="ml-auto" src={ require('../img/inst_02.png') } />
					</div>

					<div>
						<h2>Un poco de Historia</h2>
						<p className="flexible-width">Hacia 1971 la comercialización del ganado enfrentaba dificultades por el escaso poder de negociación de los productores frente a los compradores. Así nace El Rodeo S.A., la primera consignataria y rematadora de ganado del país, que se posicionó rápidamente en el mercado con los principios que la distinguen hasta hoy: seriedad comercial y defensa de los intereses de sus clientes. Asimismo, fue la primera consignataria del país en implementar las ventas a través de internet.</p>
					</div>
				</div>

				<div className="inst-block-divider">
					<div className="ml-auto-block">
						<h2>Nuestros Origenes</h2>
						<p>El Rodeo S.A. pertenece al Grupo Llano, el mismo es un conglomerado de negocios con énfasis en servicios u productos agropecuarios. Nuclea una decena de empresas dedicadas a diversos rubros, los cuales se destacan por su profesionalismo y una confiabilidad que radica en la potencia de una amalgama empresarial interdependiente y responsable, cuya profunda vocación familiar cuenta con más de 100 años de trayectoria en Paraguay.</p>
					</div>

					<div>
						<img src={ require('../img/inst_05.png') } />
					</div>
				</div>

				<div className="inst-block-divider">
					<div>
						<img className="ml-auto" src={ require('../img/inst_03.png') } />
					</div>

					<div>
						<p className="flexible-width">Los antecedentes del Grupo Llano se remontan a una tradición ganadera iniciada en España. En el siglo XIX, ésta pasa a Corrientes, Argentina y se dirige al Paraguay, donde sus empresas se proyectan mediante una diversificación con criterio de calidad, lograda gracias a alianzas profesionales y apuestas tecnológicas tendientes al mejoramiento de su producción; la cual es esencialmente ganadera, pero se desarrolla en el área de servicios técnicos, agricultura, genética, representaciones agropecuarias, consignación y transporte de ganado e insumos agropecuarios.</p>
					</div>
				</div>
			</div>

			<div className="full-bg-image-wrapper">
				<div className="centered-text">
					<h2>PROFESIONALES EN NUESTRO RUBRO</h2>
					<p>La superioridad de las ofertas del Grupo Llano se basa en los principios de honestidad y transparencia que rige sus prácticas, enlazadas a enfoques profesionales y comprometidos con las generaciones futuras, el medio ambiente y las legislaciones vigentes.</p>
					<p>La trayectoria, gran competitividad y prestigio del Grupo Llano están avaladas por múltiples galardones a nivel local, regional e internacional, los cuales confirman su perfil de excelencia y son una muestra de confianza que emana de la calidad de sus distintos productos y servicios.</p>
				</div>
			</div>

			<h2 className="text-center inst-titles">Nuestros Valores</h2>
			<div className="values-wrapper">
				<p>Confiabilidad</p>
				<p>Honestidad</p>
				<p>Profesionalismo</p>
				<p>Austeridad</p>
				<p>Trabajo en equipo</p>
				<p>Estar comprometida con las futuras generaciones</p>
			</div>
		</div>
	)
}

export default AboutUs;
