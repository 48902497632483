import React from 'react';
import CalculateTotal from './CalculateTotal';

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const ConsumoMobile = ({ dataTable, calculationType }) => {
	const cities = [
		 "Asunción",
		 "Concepción",
		 "San Pedro",
		 "Cordillera",
		 "Guairá",
		 "Caaguazú",
		 "Caazapá",
		 "Itapúa",
		 "Misiones",
		 "Paraguarí",
		 "Alto Paraná",
		 "Central",
		 "Ñeembucú",
		 "Amambay",
		 "Canindeyú",
		 "Presidente Hayes",
		 "Boquerón",
		 "Alto Paraguay"
	];

	function roundNumber(number) {
		if(typeof(number) === "number") {
			let rounded = Math.round(number);
			return rounded.toLocaleString();
		}
		else {
			return number;
		}
	}

	return (
		<div>
			{ dataTable.length > 0 ?
				<div className="consumoMobileContainer">
					{ dataTable.map((data, dataIndex) => (
						<div key={ dataIndex } className="consumoMobileWrapper">
							{ Object.entries(data).map(([key, inner], innerIndex) => (
								<div key={innerIndex} className="consumoMobileContent ">
									<p>{ capitalizeFirstLetter(key) }</p>
									{ key == "origen" ?
										<p>{ cities[inner] }</p>
										:
										<p>{ roundNumber(inner) }</p>
									}
								</div>
							))}
						</div>
					))}
					<CalculateTotal dataTable={ dataTable } calculationType={ calculationType } />
				</div>
				:
				<p className="align-center">No hay información disponible.</p>
			}
		</div>
	)
}

export default ConsumoMobile;
